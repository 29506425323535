/**
 * eslint-disable
 *
 * @format
 */

import React, { useEffect, useState } from "react";
import { Button, Modal } from "@mantine/core";
import flat from "flat";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { loadingStates, validateEmail } from "../../utilities/utilities";
import colors from "../../utilities/design";
import { showNotification } from "@mantine/notifications";
import Editor from "../../components/Editor";
import TemplatesList from "../../components/TemplateManager/TemplatesList";
import { getTemplateEditorField } from "../../utilities/apis/templates";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  createEditorField,
  getEditorField,
} from "../../utilities/apis/agreements";

const AgreementForm4 = ({ id, form, setUiConfigs, uiConfigs, save }) => {
  const agreementFormJson = flat.unflatten(form.values);

  const navigate = useNavigate();

  const [configs, setConfigs] = useState({
    templateView: false,
    editorKey: "key-1",
    templateId: null,
    editorContent: agreementFormJson.contractQuillJsDelta || {
      ops: [{ insert: "Agreement content goes here..." }],
    },
    templateField: "",
  });

  const getTemplateField = async (templateId) => {
    const formData = {
      templateId,
    };
    const response = await apiWrapWithErrorWithData(
      getTemplateEditorField(formData)
    );
    if (response.success) {
      setConfigs((prevState) => ({
        ...prevState,
        templateField: response?.getFieldName?.fieldName,
      }));
      console.log(response?.getFieldName?.fieldName);
      saveAgreementInputField(response?.getFieldName?.fieldName);
    } else {
      // showNotification({
      //   color: "red",
      //   title: "Editor Field",
      //   message: "Something went wrong.",
      // });
    }
  };
  
  const saveAgreementInputField = async (inputField) => {
    const formData = {
      fieldName: [...new Set(inputField)],
      agreementId: id,
    };
    const response = await apiWrapWithErrorWithData(
      createEditorField(formData)
    );
    if (response?.success) {
      console.log(response.success);
    } else {
      showNotification({
        message: "Error in saving Editor Field.",
        title: "Editor Field",
        color: "red",
      });
    }
  };

  const savePrev = async () => {
    await save({ ...form.values, formCompleted: true });
    setUiConfigs({
      ...uiConfigs,
      currentStep: 2,
    });
  };

  const saveNext = async () => {
    await save({
      ...form.values,
      contractQuillJsDelta: configs.editorContent,
      formCompleted: true,
    });
    navigate("/app/agreements/details/" + id);
  };

  useEffect(() => {
    if (configs.templateId) {
      getTemplateField(configs.templateId);
    }
  }, [configs.templateId]);

  return (
    <>
      {configs.templateView && (
        <Modal
          overflow="inside"
          opened
          onClose={() => {
            setConfigs((prevState) => ({
              ...prevState,
              templateView: false,
            }));
          }}
          size="calc(80vw)"
        >
          <TemplatesList
            showNewTemplateBtn={false}
            templateType="Agreements"
            useTemplateFunc={(template) => {
              console.log({
                template,
              });
              setConfigs((prevState) => ({
                ...prevState,
                templateView: false,
                editorContent: template.quillDelta,
                templateId: template.id,
                editorKey: "key-" + Math.random(),
              }));
            }}
          />
        </Modal>
      )}
      <div className="w-full flex flex-col my-4">
        <div className="flex my-4 justify-end">
          <Button
            onClick={() => {
              setConfigs((prevState) => ({
                ...prevState,
                templateView: true,
              }));
            }}
            style={{
              backgroundColor: "#46BDE1",
            }}
          >
            Choose from template
          </Button>
        </div>
        <div className="flex justify-center mt-4">
          <Editor
            key={configs.editorKey}
            content={configs.editorContent}
            agreementId={id}
            templateId={configs.templateId}
            onContentChange={(content) => {
              setConfigs((prevState) => ({
                ...prevState,
                editorContent: content,
              }));
            }}
          />
        </div>
        <div className="flex flex-row justify-between">
          {uiConfigs.loading === loadingStates.LOADING ? (
            <BeatLoader color={colors.primary} size={10} />
          ) : (
            <>
              <Button
                className="max-w-xs mt-20"
                onClick={savePrev}
                style={{
                  backgroundColor: "#46BDE1",
                }}
              >
                Previous
              </Button>
              <Button
                className="max-w-xs mt-20"
                onClick={saveNext}
                style={{
                  backgroundColor: "#46BDE1",
                }}
              >
                Finish
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AgreementForm4;
