/** @format */

import styles from "./AgreementBulkConfirm.module.css";
import { Button, Text } from "@mantine/core";
import { BeatLoader } from "react-spinners";
import { useState } from "react";

function AgreementBulkConfirm({
  bulkAgreementData,
  onPrevious,
  onSubmit,
  loading,
}) {
  const [validateFlag, setValidateFlag] = useState(false);
  const headers = Object.keys(bulkAgreementData[0] ? bulkAgreementData[0] : []);

  const requiredFields = [
    "Template Id",
    "Signature Type",
    "Agreement Type",
    "Type Of Agreement",
    "Title Of Agreement",
    "Requesting User Name",
    "Requesting User Email",
    "No Of Years",
    "Reminder Time In Days",
    "Location",
    "First Party Name",
    "First Party Email",
    "Second Party Name",
    "Second Party Email",
  ];

  const validate = ({ property, value }) => {
    if (property.startsWith("ph_") && (value === null || value === "")) {
      if(!validateFlag) setValidateFlag(true);
      return true;
    } else if (
      requiredFields.includes(property) &&
      (value === null || value === "")
    ) {
      if(!validateFlag) setValidateFlag(true);
      return true;
    }
    return false;
  };

  const rows = bulkAgreementData?.map((element, i) => (
    <tr key={i}>
      {headers?.map((header, j) => (
        <td
          key={j}
          className={styles.tdandthCss}
          style={{
            backgroundColor: validate({
              property: header,
              value: element[header],
            })
              ? "#ff9999"
              : "",
          }}
        >
          {element[header]}
        </td>
      ))}
    </tr>
  ));

  return (
    <div style={{ marginLeft: "20px" }}>
      <h3>Uploaded Data Verification:</h3>
      <table className={styles.tableCss}>
        <thead>
          <tr>
            {headers?.map((header, i) => (
              <th key={i} className={styles.tdandthCss}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      <div className="my-4">
        <Button onClick={onPrevious} disabled={loading}>previous</Button>
        <Button
          className="mx-4"
          color="green"
          disabled={loading || validateFlag}
          onClick={onSubmit}
        >
          {loading ? (
            <BeatLoader size={10} color="green" />
          ) : (
            <Text>submit</Text>
          )}
        </Button>
      </div>
    </div>
  );
}

export default AgreementBulkConfirm;
