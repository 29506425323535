import { showNotification } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Skeleton } from '@mantine/core';
import { loadingStates } from '../../utilities/utilities';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';
// import { expenseMonthlyApi } from '../../utilities/apis/budgetManager';
import { getNoticeByStatus } from '../../utilities/apis/notices';

function NoticeGraph() {
  const [configs, setConfigs] = useState({
    listLoading: loadingStates.NO_ACTIVE_REQUEST,
    noticesLoading: loadingStates.NO_ACTIVE_REQUEST,
    list: null,
    noticesList: null,
    // module: 'Case',
  });
  const fetchNoticesByStatus = async () => {
    setConfigs((prevState) => ({
      ...prevState,
      noticesLoading: loadingStates.LOADING,
    }));
    const resp = await apiWrapWithErrorWithData(getNoticeByStatus());
    if (resp?.success) {
      setConfigs((prevState) => ({
        ...prevState,
        noticesLoading: loadingStates.NO_ACTIVE_REQUEST,
        noticesList: resp.getNotices,
        // console.log(top);
      }));
    } else {
      setConfigs((prevState) => ({
        ...prevState,
        noticesLoading: loadingStates.NO_ACTIVE_REQUEST,
      }));
      // showNotification({
      //   title: 'Cases',
      //   message: "Couldn't load Monthly Cases",
      //   color: 'red',
      // });
    }
  };

  useEffect(() => {
    fetchNoticesByStatus();
  }, []);
  return (
    <div className="pb-12">
      <div
        className="border-solid border-gray-100 border-2 rounded-lg p-4 mt-4"
        style={{ border: 'none', boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1)', width: '50%' }}
      >
        <div className="mt-2">
          Notices Completed Month wise
        </div>
        {configs.noticesLoading === loadingStates.LOADING
          && <Skeleton style={{ height: '200px' }} className="mt-4 mx-8" visible />}
        {configs.noticesLoading === loadingStates.NO_ACTIVE_REQUEST && configs.noticesList
            && (
            <div
              className="mt-4 w-full"
              style={{
                // height: '350px',
                height: '200px',
                width: '100%',
                // width: '30%',
              }}
            >
              <Bar
            // data={state}
                data={{
                  // labels: ['January', 'February', 'March',
                  //   'April', 'May', 'June', 'July', 'August',
                  //  'September', 'October', 'November', 'December'],
                  // eslint-disable-next-line dot-notation
                  labels: configs.noticesList.map((el) => `${el.month} ${el.year}, No. of Notice ${el['_count'].id}`),
                  datasets: [
                    {
                      label: 'Notices Completed',
                      backgroundColor: 'rgba(140, 233, 154, 0.6)',
                      barThickness: 30,
                      barPercentage: 0.5,
                      // eslint-disable-next-line dot-notation
                      data: configs.noticesList.map((el) => el['_avg'].noOfCompletionDays),
                      //   data: [65, 59, 80, 81, 56],
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    // title: {
                    //   display: true,
                    //   text: 'Cases Completed',
                    // },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: 'Average Time(in days)',
                      },
                    },
                    x: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        // text: 'Months',
                        text: 'No. of Completed Notices Month wise',
                      },
                    },
                  },
                }}
              />
            </div>
            )}
      </div>
    </div>
  );
}

export default NoticeGraph;
