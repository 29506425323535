/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import {
  ActionIcon,
  Button,
  Pagination,
  Table,
  Badge,
  Skeleton,
  Text,
  TextInput,
  Anchor,
  Select,
} from "@mantine/core";
import {
  // Edit, Eye, Search, SortAscending,
  Eye,
  Search,
  SortAscending,
} from "tabler-icons-react";
import { useDebouncedValue } from "@mantine/hooks";
import {
  formatDate,
  getValueForInput,
  loadingStates,
  csvDownload
} from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getCasesList, getCasesListCsv } from "../../utilities/apis/cases";
import { caseStatusColors } from "../../utilities/enums";
import CaseAnalytics from "../../components/CaseAnalytics/CaseAnalytics";
import DashboardNotifications from "../DashboardNotifications";
import colors, { themeColor } from "../../utilities/design";
import CaseGraph from "./CaseGraph";
import { caseStatusValues } from "../../utilities/enums";

const CaseListingPage = () => {
  const [listing, setListing] = useState({
    list: null,
    loaded: false,
    casesCount: null,
    filterOptions: {},
    sortBy: "createdAt",
    sortDirection: "desc",
    searchString: "",
    caseStatusType: "",
  });
  const [debouncedSearchInput] = useDebouncedValue(listing.searchString, 500);
  const [configs, setConfigs] = useState({
    page: 1,
  });
  const [uiConfigs, setUiConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
  });
  const [casePayload, setCasePayload] = useState({});
  const [exportButtonLoading, setExportButtonLoading] = useState(false);
  const sortOptions = [
    {
      label: "Created",
      value: "createdAt",
    },
    {
      label: "Status",
      value: "status",
    },
    {
      label: "Next Date",
      value: "nextDate",
    },
  ];
  const fetchCaseList = async ({ page }) => {
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.LOADING,
    });
    const payload = {
      page: page ? page : configs.page,
      sortByOptions: {
        [listing.sortBy]: listing.sortDirection,
      },
      filterOptions: listing.caseStatusType
        ? {
            status: {
              contains: listing.caseStatusType,
            },
          }
        : {
            OR: [
              {
                caseData: {
                  path: "$.json.caseNumber.number",
                  string_contains: debouncedSearchInput,
                },
              },
              {
                caseData: {
                  path: "$.json.caseNumber.type",
                  string_contains: debouncedSearchInput,
                },
              },
              {
                caseData: {
                  path: "$.json.caseNumber.year",
                  string_contains: debouncedSearchInput,
                },
              },
              {
                caseData: {
                  path: "$.json.courtDetails.city",
                  string_contains: debouncedSearchInput,
                },
              },
              {
                caseData: {
                  path: "$.json.courtDetails.state",
                  string_contains: debouncedSearchInput,
                },
              },
              {
                caseData: {
                  path: "$.json.complainant[0].name",
                  string_contains: debouncedSearchInput,
                },
              },
              {
                caseData: {
                  path: "$.json.respondent[0].name",
                  string_contains: debouncedSearchInput,
                },
              },
              {
                status: {
                  contains: debouncedSearchInput,
                },
              },
              // {
              //   caseData: {
              //     path: '$.json.complainant[0].name',
              //     string_contains: debouncedSearchInput,
              //   },
              // },
              // {
              //   caseData: {
              //     path: '$.json.caseRecord.fixedFor',
              //     string_contains: debouncedSearchInput,
              //   },
              // },
            ],
          },
    };
    setCasePayload(payload);
    const response = await apiWrapWithErrorWithData(
      getCasesList({ ...payload })
    );
    if (response?.success && response?.cases) {
      setListing((prevState) => ({
        ...prevState,
        list: response?.cases,
        loaded: true,
        casesCount: response?.casesCount,
      }));
    } else {
      // showNotification(({
      //   color: 'red',
      //   title: 'Case',
      //   message: 'Something went wrong.',
      // }));
    }
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.NO_ACTIVE_REQUEST,
    });
  };
  useEffect(() => {
    fetchCaseList({ page: null });
  }, [
    configs.page,
    listing.sortBy,
    listing.sortDirection,
    debouncedSearchInput,
    listing.caseStatusType,
  ]);

  useEffect(() => {
    fetchCaseList({ page: 1 });
  }, [listing.caseStatusType]);

  const data = listing?.list;
  // useEffect(() => {
  //   fetchCaseList();
  // }, []);
  const navigate = useNavigate();
  const onViewClick = (caseId) => {
    navigate(`/app/dispute-manager/cases/details/${caseId}`);
  };

  // const onEditClick = (caseId) => {
  //   navigate('/app/dispute-manager/edit-case', {
  //     state: {
  //       caseId,
  //     },
  //   });
  // };

  const statusOptions = [
    { label: "CREATED", value: "CREATED" },
    ...caseStatusValues.map((val) => ({ label: val, value: val })),
  ];

  const handleCsvDownload = async () => {
    try {
      setExportButtonLoading(true);
      const response = await apiWrapWithErrorWithData(
        getCasesListCsv({ ...casePayload })
      );
      if (response.success) {
        if (response?.cases.length > 0) {
          const columnHeaders = Object.keys(response.cases[0]);
          csvDownload({
            data: response.cases,
            headers: columnHeaders,
            name: "case",
          });
        } else {
          showNotification({
            title: "Case csv download",
            message: "No case available",
            color: "red",
          });
        }
      }
      setExportButtonLoading(false);
    } catch (error) {
      setExportButtonLoading(false);
      showNotification({
        title: "Case csv download",
        message: "Could not load case csv",
        color: "red",
      });
    }
  };

  return (
    <>
      <div className="flex flex-col my-2">
        <Text>Case Notifications</Text>
        <DashboardNotifications
          onBtnClick={() => {
            navigate("/app/notifications", {
              state: {
                filterOptions: "cases",
              },
            });
          }}
          filterOptions={{
            caseId: {
              not: null,
            },
          }}
        />
      </div>
      <div className="flex flex-row w-full">
        <CaseAnalytics />
      </div>
      <CaseGraph />
      <div className="flex justify-between items-center">
        <Text>Cases List</Text>
        <Button
          color={themeColor(colors.cases)}
          className="my-2 w-60"
          onClick={() => navigate("/app/dispute-manager/edit-case")}
        >
          Create New Case
        </Button>
      </div>
      {/* {uiConfigs.loading === loadingStates.LOADING */}
      {/*    && ( */}
      {/*    <div className="flex justify-between my-4"> */}
      {/*      <Skeleton height={40} radius="md" className="w-40" /> */}
      {/*      <Skeleton height={40} radius="md" className="w-40" /> */}
      {/*    </div> */}
      {/*    )} */}
      {/* {(uiConfigs.loading !== loadingStates.LOADING && listing.loaded && data) */}
      {/*    && ( */}
      {/*    <CaseFilterSortBar */}
      {/*      configs={configs} */}
      {/*      setConfigs={setConfigs} */}
      {/*    /> */}
      {/*    )} */}
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col">
          <Text size="xs" className="mb-2" color="gray">
            Case sensitive search
          </Text>
          <div className="flex justify-between">
            <div className="flex items-center">
              <TextInput
                value={listing.searchString}
                onChange={(input) => {
                  const val = getValueForInput(input);
                  setListing((prevState) => ({
                    ...prevState,
                    searchString: val,
                  }));
                }}
                placeholder="Type to search..."
                icon={<Search size={14} />}
              />
              <Select
                className="mx-2"
                placeholder="Select case type"
                value={listing.caseStatusType}
                onChange={(val) => {
                  setListing((prevState) => ({
                    ...prevState,
                    caseStatusType: val,
                  }));
                }}
                data={statusOptions}
              />
              <Anchor
                className="ml-2"
                onClick={() => {
                  setListing((prevState) => ({
                    ...prevState,
                    searchString: "",
                    caseStatusType: "",
                  }));
                }}
              >
                Clear
              </Anchor>
              <Button
                className="mx-2"
                disabled={exportButtonLoading}
                onClick={handleCsvDownload}
              >
                export
              </Button>
            </div>
          </div>
        </div>
        {/* <div className="flex items-center">
          Sort:
          <Select
            className="mx-2"
            value={listing.sortBy}
            onChange={(val) => {
              setListing((prevState) => ({
                ...prevState,
                sortBy: val,
              }));
            }}
            data={sortOptions}
          />
          <ActionIcon
            onClick={() => {
              setListing((prevState) => ({
                ...prevState,
                sortDirection: prevState.sortDirection === 'asc' ? 'desc' : 'asc',
              }));
            }}
            color="blue"
            className={listing.sortDirection === 'asc' ? '' : 'rotate-180'}
          >
            <SortAscending />
          </ActionIcon>
        </div> */}
      </div>
      {uiConfigs.loading === loadingStates.LOADING && (
        <div className="flex flex-col">
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          {/* addd 5 rows */}
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <div className="flex flex-row justify-center">
            <Skeleton height={40} radius="md" className="w-40" />
          </div>
        </div>
      )}
      {uiConfigs.loading !== loadingStates.LOADING &&
        listing.loaded &&
        data && (
          <div className="flex flex-col">
            <Table striped>
              <thead>
                <tr>
                  <th>Reference No.</th>
                  <th>Case No.</th>
                  <th>Next Date</th>
                  <th>Status</th>
                  <th>Fixed For</th>
                  <th>Created Date</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row) => (
                  <tr key={row.id}>
                    <td>{`C - ${row.id}`}</td>
                    <td>
                      {`${row.caseNumber.type} - ${row.caseNumber.number} / ${row.caseNumber.year} `}
                    </td>
                    <td>{formatDate(row.caseRecord?.nextHearing)}</td>
                    <td>
                      <Badge color={caseStatusColors[row.status] || "orange"}>
                        {row.status}
                      </Badge>
                    </td>
                    <td>{row.caseRecord?.fixedFor}</td>
                    <td>{formatDate(row.createdAt)}</td>
                    <td>
                      <div className="flex flex-row mr-4">
                        {/* <ActionIcon
                            onClick={() => {
                              onEditClick(row.id);
                            }}
                            color="white"
                          >
                            <Edit size={24} />
                          </ActionIcon> */}
                        {/* {row.formCompleted */}
                        {/* && ( */}
                        <ActionIcon
                          // className="mx-2"
                          onClick={() => {
                            onViewClick(row.id);
                          }}
                          color="white"
                        >
                          <Eye size={24} />
                        </ActionIcon>
                        {/* )} */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {listing.casesCount && (
              <div className="flex flex-row justify-center my-4">
                <Pagination
                  page={configs.page}
                  onChange={(page) => {
                    setConfigs({
                      ...configs,
                      page,
                    });
                  }}
                  total={Math.ceil(listing.casesCount / 10)}
                />
              </div>
            )}
          </div>
        )}
    </>
  );
};

export default CaseListingPage;
