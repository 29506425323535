/** @format */

import styles from "../../components/TemplateManager/TemplateRow.module.css";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getClauseLibraryById,
  createClause,
  deleteClause,
  deleteClauseLibrary,
  getClauseById,
  updateClauseById,
} from "../../utilities/apis/clause";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import ClauseDetails from "./ClauseDetails";

import React, { useEffect, useState } from "react";
import { Button, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useNavigate, useParams } from "react-router-dom";
import { CreateClauseModal } from "./CreateClauseModal";

function CreateClause() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [clauseModalConfig, setClauseModalConfig] = useState({
    open: false,
    buttonLoading: false,
    deleteLoading: false,
  });
  const [clauseData, setClauseData] = useState([]);
  const clauseTemplate = {
    title: "",
    text: "",
    tags: [],
  };
  const [creationClause, setCreationClause] = useState({ ...clauseTemplate });
  const [editClauseData, setEditClauseData] = useState({});
  const [open, setOpen] = useState(false);
  const [clauseDetailsOpen, setClauseDetailsOpen] = useState(false);

  const getClauseLibraryByIdApi = async () => {
    try {
      const response = await apiWrapWithErrorWithData(
        getClauseLibraryById({ id })
      );
      if (response.success) {
        console.log({ response });
        setClauseData(response?.clauseLibrary?.clause || []);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getClauseLibraryByIdApi();
  }, []);

  const createClauseApi = async () => {
    if (
      !creationClause.tags.length ||
      !creationClause.text ||
      !creationClause.title
    ) {
      showNotification({
        color: "red",
        title: "Clause",
        message: "Enter all the fields",
      });
      return;
    }

    setClauseModalConfig((preState) => ({ ...preState, buttonLoading: true }));
    try {
      if (editClauseData?.id) {
        const tags = editClauseData.clauseTag?.map((a) => a.tag.title);

        let tempCreationClause = [];
        if (typeof creationClause.tags[0] === "object") {
          tempCreationClause = creationClause.tags?.map(({ value }) => value);
        } else {
          tempCreationClause = [...creationClause.tags];
        }

        const tempRemovedTags = [];
        const newTags = [];

        tags.forEach((tag) => {
          if (!tempCreationClause.includes(tag)) {
            tempRemovedTags.push(tag);
          }
        });

        tempCreationClause.forEach((tag) => {
          if (!tags.includes(tag)) {
            newTags.push(tag);
          }
        });

        const removedTags = [];
        editClauseData.clauseTag?.forEach((a) => {
          if (tempRemovedTags.includes(a.tag.title)) {
            removedTags.push(a);
          }
        });

        const payload = {
          title: creationClause.title,
          text: creationClause.text,
          newTags,
          removedTags,
        };

        const response = await apiWrapWithErrorWithData(
          updateClauseById({ ...payload, id: editClauseData?.id })
        );

        if (response.success) {
          getClauseLibraryByIdApi();
          setClauseModalConfig((preState) => ({ ...preState, open: false }));
          setCreationClause({ ...clauseTemplate });
          setEditClauseData({});
          showNotification({
            color: "green",
            title: "Clause",
            message: "Clause is updated",
          });
        } else {
          showNotification({
            color: "red",
            title: "Clause",
            message: "Unable to update Clause",
          });
        }
      } else {
        const response = await apiWrapWithErrorWithData(
          createClause({ ...creationClause, clauseLibraryId: id })
        );
        if (response.success) {
          showNotification({
            color: "green",
            title: "Clause",
            message: "Clause is created",
          });
          setCreationClause({ ...clauseTemplate });
          setClauseModalConfig((preState) => ({ ...preState, open: false }));
          getClauseLibraryByIdApi();
        } else {
          throw Error(response);
        }
      }
    } catch (error) {
      console.log({ error });
      showNotification({
        color: "red",
        title: "Clause",
        message: "Unable to create clause",
      });
    }
    setClauseModalConfig((preState) => ({ ...preState, buttonLoading: false }));
  };

  const deleteClauseApi = async (id) => {
    setClauseModalConfig((preState) => ({ ...preState, deleteLoading: true }));
    try {
      const response = await apiWrapWithErrorWithData(
        deleteClause({ clauseId: id })
      );
      if (response.success) {
        showNotification({
          color: "green",
          title: "Clause",
          message: "Clause is deleted",
        });
        getClauseLibraryByIdApi();
      } else {
        throw Error(response);
      }
    } catch (error) {
      console.log({ error });
      showNotification({
        color: "red",
        title: "Clause",
        message: "Unable to delete Clause",
      });
    }
    setClauseModalConfig((preState) => ({ ...preState, deleteLoading: false }));
  };

  const deleteClauseLibraryApi = async () => {
    setClauseModalConfig((preState) => ({ ...preState, deleteLoading: true }));
    try {
      const response = await apiWrapWithErrorWithData(
        deleteClauseLibrary({ clauseLibraryId: id })
      );
      if (response.success) {
        showNotification({
          color: "green",
          title: "Clause",
          message: "Clause Library is deleted",
        });
        navigate("/app/templates/clause");
      } else {
        throw Error(response);
      }
    } catch (error) {
      console.log({ error });
      showNotification({
        color: "red",
        title: "Clause",
        message: "Unable to delete Clause Library",
      });
    }
    setClauseModalConfig((preState) => ({ ...preState, deleteLoading: false }));
    setOpen(false);
  };

  const editClause = async (id) => {
    try {
      const response = await apiWrapWithErrorWithData(getClauseById({ id }));
      if (response.success) {
        const clause = response?.clause;
        setEditClauseData(clause);
        setCreationClause({
          title: clause.title,
          text: clause.text,
          tags:
            clause.clauseTag?.map((a) => ({
              label: a.tag.title,
              value: a.tag.title,
            })) || [],
        });
        setClauseModalConfig((preState) => ({ ...preState, open: true }));
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <>
      <CreateClauseModal
        clauseModalConfig={clauseModalConfig}
        setClauseModalConfig={setClauseModalConfig}
        clauseTemplate={clauseTemplate}
        creationClause={creationClause}
        setCreationClause={setCreationClause}
        setEditClauseData={setEditClauseData}
        createClauseApi={createClauseApi}
      />
      <div className="flex flex-col pt-8 pb-14 pl-6 pr-16">
        <div className="flex justify-between">
          <div className={styles.title}>{`Caluse Library`}</div>
          <Button
            className="ml-4"
            style={{
              backgroundColor: "#ff4d4d",
              borderRadius: "0.5rem",
              color: "#F5F5F5",
            }}
            onClick={() => setOpen(true)}
          >
            Delete
          </Button>
        </div>
        <div className="flex flex-row justify-between">
          <div className={`${styles.text} mt-1`}></div>
        </div>
        <div className="flex justify-between my-4 items-center">
          <div></div>
          <Button
            onClick={() =>
              setClauseModalConfig((preState) => ({
                ...preState,
                open: true,
              }))
            }
            style={{
              backgroundColor: "#46BDE1",
            }}
          >
            Add Clause
          </Button>
        </div>
        <div>
          {clauseData?.length ? (
            clauseData.map((item) => (
              <ClauseDetails
                key={item.id}
                item={item}
                clauseModalConfig={clauseModalConfig}
                deleteClauseApi={deleteClauseApi}
                editClause={editClause}
                setOpen={setClauseDetailsOpen}
                open={clauseDetailsOpen}
              />
            ))
          ) : (
            <div className="my-4 flex justify-center">
              <Text>No Clauses</Text>
            </div>
          )}
        </div>
      </div>
      <DeleteConfirmationModal
        open={open}
        setOpen={setOpen}
        deleteHandler={deleteClauseLibraryApi}
        deleteLoading={clauseModalConfig.deleteLoading}
        text={"Do you want to delete this clause library?"}
      />
    </>
  );
}

export default CreateClause;
