/** @format */

import React, { useState } from "react";
import { Container, Pagination, Table, Button, Text } from "@mantine/core";

function Document() {
  const PAGE_SIZE = 10;
  const [page, setPage] = useState(1);
  const data = [
    { id: 1, name: "John Doe", age: 25 },
    { id: 2, name: "Jane Smith", age: 32 },
  ];

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text>No templates</Text>
        <Button>create</Button>
      </div>
      <Container className="my-3">
        <Table striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Age</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.age}</td>
              </tr>
            ))}
          </tbody>  
        </Table>
        <Pagination
          value={page}
          onChange={setPage}
          total={Math.ceil(data.length / PAGE_SIZE)}
        />
      </Container>
    </>
  );
}

export default Document;
