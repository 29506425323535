/** @format */

import flat from "flat";
import {
  Button,
  SegmentedControl,
  Select,
  Text,
  // Button, SegmentedControl, Text,
} from "@mantine/core";
// import React, { useState } from 'react';
import React from "react";
import { showNotification } from "@mantine/notifications";
import { BeatLoader } from "react-spinners";
import useMultiAddress from "../../../hooks/useMultiAddress";
import CompleteAddress from "../../../components/CompleteAddress";
import { getValueForInput, loadingStates } from "../../../utilities/utilities";
import colors from "../../../utilities/design";

const NoticeForm1 = ({ noticeForm, uiConfigs, setUiConfigs, saveNotice }) => {
  const noticeFormUnflatted = flat.unflatten(noticeForm.values);

  // const changeHandler = (name) => (inputValue) => {
  //   const value = getValueForInput(inputValue);
  //   noticeForm.setValues((data) => ({
  //     ...data,
  //     [name]: value,
  //   }));
  // };
  console.log({ noticeFormUnflatted });
  const runOnSaveReceivers = (data) => {
    noticeForm.setValues({
      ...noticeForm.values,
      ...flat({ receivers: data }),
    });
  };

  const runOnSaveSenders = (data) => {
    noticeForm.setValues({
      ...noticeForm.values,
      ...flat({ senders: data }),
    });
  };

  const [
    multiData,
    add,
    setMode,
    onSave,
    onDelete,
    editOrAddAllowed,
    deleteAllowed,
    getMode,
  ] = useMultiAddress(noticeFormUnflatted.receivers, runOnSaveReceivers);

  const [
    multiDataSenders,
    addSenders,
    setModeSenders,
    onSaveSenders,
    onDeleteSenders,
    editOrAddAllowedSenders,
    deleteAllowedSenders,
    getModeSenders,
  ] = useMultiAddress(noticeFormUnflatted.senders, runOnSaveSenders);

  const validate = () => {
    const keys = {};
    if (!noticeFormUnflatted.noticeTitle) {
      keys["noticeTitle"] = "Please select notice title.";
    }

    if (Object.keys(keys).length !== 0) {
      noticeForm.setErrors(keys);
      showNotification({
        color: "red",
        title: "Notice Form",
        message: "Please check all fields are filled properly.",
      });
    }

    return Object.keys(keys).length === 0;
  };

  const saveNext = async () => {
    if (validate()) {
      await saveNotice();
      setUiConfigs((stateC) => ({
        ...stateC,
        currentStep: 1,
      }));
    }
  };
  // const [titleData, setTitleData] = useState([]);
  // console.log(noticeFormUnflatted.noticeTitles);
  // console.log('title on noticeForm1 = ', noticeFormUnflatted.noticeTitle);
  return (
    <div className="flex flex-col py-4 px-2">
      <div className="flex flex-row items-center mb-4">
        <div className="mr-8 ml-2">
          <Select
            required
            label="Title of Notice"
            data={noticeFormUnflatted.noticeTitles}
            placeholder="enter notice title"
            searchable
            creatable
            // value={noticeFormUnflatted.noticeTitle}
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              noticeForm.setFieldValue(
                "noticeTitles",
                Array.from(
                  new Set([...(noticeForm.values.noticeTitles || []), query])
                )
              );
            }}
            {...noticeForm.getInputProps("noticeTitle")}
          />
        </div>
        <div style={{ width: "200px" }} className="mt-6">
          <SegmentedControl
            color="cyan"
            data={["Outgoing", "Incoming"]}
            value={noticeFormUnflatted.noticeType}
            onChange={(input) => {
              const val = getValueForInput(input);
              noticeForm.setFieldValue("noticeType", val);
            }}
          />
        </div>
      </div>
      <div className="border-solid border-2 border-gray-100 rounded-xl p-6 flex-col mx-2">
        <Text size="xl" className="my-2">
          Receivers
        </Text>
        {multiData.map((dataNode, index) => (
          <CompleteAddress
            withDivider={index !== multiData.length - 1}
            data={dataNode}
            onDelete={onDelete(index)}
            setMode={setMode(index)}
            onSave={onSave(index)}
            allowEdit={editOrAddAllowed}
            placeholderText={`Complainant ${index + 1}`}
            deleteAllowed={deleteAllowed}
            mode={getMode(index)()}
            isLawyer={false}
          />
        ))}
        {editOrAddAllowed && (
          <Button
            variant="outline"
            className="w-60"
            onClick={add}
            style={{ color: "#46BDE1", borderColor: "#46BDE1" }}
          >
            Add New Receiver
          </Button>
        )}
      </div>

      <div className="border-solid border-2 border-gray-100 rounded-xl p-6 flex-col mx-2 my-4">
        <Text size="xl" className="my-2">
          Senders
        </Text>
        {multiDataSenders.map((dataNode, index) => (
          <CompleteAddress
            withDivider={index !== multiDataSenders.length - 1}
            data={dataNode}
            onDelete={onDeleteSenders(index)}
            setMode={setModeSenders(index)}
            onSave={onSaveSenders(index)}
            allowEdit={editOrAddAllowedSenders}
            placeholderText={`Complainant ${index + 1}`}
            deleteAllowed={deleteAllowedSenders}
            mode={getModeSenders(index)()}
            isLawyer={false}
          />
        ))}
        {editOrAddAllowed && (
          <Button
            variant="outline"
            className="w-60"
            onClick={addSenders}
            style={{ color: "#46BDE1", borderColor: "#46BDE1" }}
          >
            Add New Sender
          </Button>
        )}
      </div>

      <div className="flex flex-row justify-between">
        <div />
        {editOrAddAllowed && editOrAddAllowedSenders && (
          <div>
            {uiConfigs.loading === loadingStates.LOADING ? (
              <BeatLoader color={colors.primary} size={10} />
            ) : (
              <Button
                className="max-w-xs mt-20"
                onClick={saveNext}
                style={{ backgroundColor: "#46BDE1" }}
              >
                Next
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NoticeForm1;
