/** @format */

import React, { useState } from "react";
import { Divider, Text, Select } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { MdClose } from "react-icons/md";
import DashboardNotifications from "./DashboardNotifications";
import DashboardTasks from "./DashboardTasks";
import DashboardAnalytics from "./DashboardAnalytics";
import DashboardGraph from "./DashboardGraph";
import TatChart from "./tatChart";

const DashboardNew = () => {
  const [month, setMonth] = useState(0);

  const statusOptions = [
    {
      label: "1 month",
      value: "1",
    },
    {
      label: "3 months",
      value: "3",
    },
    {
      label: "6 months",
      value: "6",
    },
    {
      label: "1 year",
      value: "12",
    },
  ];

  return (
    <div className="flex flex-col p-4">
      <Text>Notifications</Text>
      <DashboardNotifications />
      <div className="flex flex-col mt-4 w-full pt-5">
        <div className="flex justify-end">
          <Select
            className="mx-2 my-2"
            placeholder="Select duration"
            rightSection={
              month ? (
                <MdClose size="1rem" onClick={() => setMonth(0)} />
              ) : (
                <IconChevronDown size="1rem" />
              )
            }
            value={month}
            onChange={(val) => setMonth(val)}
            data={statusOptions}
          />
        </div>
        <DashboardAnalytics month={month}/>
      </div>
      <TatChart month={month}/>
      <DashboardGraph month={month}/>
      {/* <div className="flex flex-col mt-4 w-full">
        <Divider className="w-full my-2" />
        <Text>My Tasks</Text>
        <DashboardTasks />
      </div> */}
    </div>
  );
};

export default DashboardNew;
