import axios from 'axios';
import { headersProvider } from '../apiHelpers';
import constants from '../constants';

export const financialAnalyticsApi = () => axios.get(`${constants.utilityHost}/financials/analytics`, {
  headers: headersProvider(),
});

export const financialAnalyticsDetailsApi = () => axios.get(`${constants.utilityHost}/financials/analytics-details`, {
  headers: headersProvider(),
});

export const financialListApi = ({
  module,
}) => axios.get(`${constants.utilityHost}/financials/list?module=${module}`, {
  headers: headersProvider(),
});

export const topServiceProvidersApi = () => axios.get(`${constants.utilityHost}/financials/top-service-providers`, {
  headers: headersProvider(),
});

export const expenseMonthlyApi = ({ month }) => axios.get(`${constants.utilityHost}/financials/month-expense/${month}`, {
  headers: headersProvider(),
});

export const getBudgetAmount = () => axios.get(`${constants.utilityHost}/financials/get-budget-amount`, {
  headers: headersProvider(),
});

export const createFinancialData = ({
  budgetData,
}) => axios.post(`${constants.utilityHost}/financials/budget-amount`, {
  ...budgetData,
}, {
  headers: headersProvider(),
});

export const getBudgetAmountYearly = () => axios.get(`${constants.utilityHost}/financials/budget/details`, {
  headers: headersProvider(),
});
