/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import flat from 'flat';
import { Stepper } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useLocation } from 'react-router-dom';
// import { subDays, formatISO } from 'date-fns/esm';
// import { addDays } from 'date-fns/esm';
// import { formatISO, subDays, addDays } from 'date-fns';
import {
  subDays, addDays, parseISO,
} from 'date-fns/esm';
import AgreementForm1 from './AgreementForm1';
import {
  createAgreement,
  getAgreementDetails,
  getAgreementTitleApi,
  getAgreementTypesApi,
  getRegisterUserApi,
  updateAgreement,
} from '../../utilities/apis/agreements';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';
import { loadingStates } from '../../utilities/utilities';
import AgreementForm2 from './AgreementForm2';
import AgreementForm3 from './AgreementForm3';
import AgreementForm4 from './AgreementForm4';
import colors, { themeColor } from '../../utilities/design';

const AgreementCreatePage = () => {
  const FORM_STEPS = {
    STEP_1: 0,
    STEP_2: 1,
    STEP_3: 2,
    STEP_4: 3,
  };
  const initialState = {
    typeOfAgreementData: [],
    typeOfAgreement: null,
    titleOfAgreementData: [],
    titleOfAgreement: null,
    requestingUser: {
      name: '',
      email: '',
      phone: '',
      designation: '',
      businessDepartment: '',
    },
    dateOfContract: null,
    noOfMonths: null,
    noOfYears: '1',
    reminderTimeInDays: '30',
    contractQuillJsDelta: {
      ops: [{ insert: 'Agreement content goes here...' }],
    },
    firstParty: {},
    secondParty: {},
    thirdParty: {},
    fourthParty: {},
    fifthParty: {},
    documents: [],
    formCompleted: false,
    location: null
  };
  const { state } = useLocation();
  const [agreementDetails, setAgreementDetails] = useState({
    agreementLoaded: false,
    agreementId: state?.agreementId || null,
  });

  const [uiConfigs, setUiConfigs] = useState({
    currentStep: FORM_STEPS.STEP_1,
    loading: loadingStates.NO_ACTIVE_REQUEST,
  });
  const agreementForm = useForm({ initialValues: flat(initialState) });

  const saveAgreement = async (values) => {
    const formValues = flat.unflatten(values || agreementForm.values);
    const agreementData = ({
      json: flat.unflatten(values || agreementForm.values),
      flatted: values || (agreementForm.values),
    });
    const toUse = agreementDetails.agreementId ? updateAgreement : createAgreement;

    const contractMonths = formValues.noOfMonths ? parseInt(formValues.noOfMonths, 10) * 30 : null;
    const contractYears = formValues.noOfYears ? parseInt(formValues.noOfYears, 10) * 365 : null;
    const totalDays = contractMonths + contractYears;

    const contractDueDate = addDays(parseISO(formValues.dateOfContract), totalDays);
    const agreementArgs = {
      typeOfAgreement: formValues.typeOfAgreement,
      titleOfAgreement: formValues.titleOfAgreement,
      requestingUserDetails: formValues.requestingUser,
      dateOfContract: formValues.dateOfContract,
      contractTermInDays: totalDays,
      noOfYears: parseInt(formValues.noOfYears, 10),
      noOfMonths: parseInt(formValues.noOfMonths, 10),
      location: formValues.location,
      reminderTimeInDays: parseInt(formValues.reminderTimeInDays, 10),
      dueDateOfContract: contractDueDate,
      reminderTime: subDays(contractDueDate, formValues.reminderTimeInDays),
      contractQuillJsDelta: formValues.contractQuillJsDelta,
      firstParty: formValues.firstParty,
      secondParty: formValues.secondParty,
      thirdParty: formValues.thirdParty,
      fourthParty: formValues.fourthParty,
      fifthParty: formValues.fifthParty,
      formCompleted: !!formValues.formCompleted,
    };

    const args = agreementDetails.agreementId
      ? ({ agreementId: agreementDetails.agreementId, agreementData, ...agreementArgs })
      : ({ agreementData, ...agreementArgs });
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.LOADING,
    });
    const response = await apiWrapWithErrorWithData(toUse(args));
    if (response?.success) {
      // showNotification({
      //   color: 'green',
      //   title: 'Agreement Form',
      //   message: 'Details saved.',
      // });
      if (response?.agreement?.id) {
        setAgreementDetails({
          ...agreementDetails,
          agreementId: response.agreement.id,
          agreementLoaded: true,
        });
      }
    } else {
      // showNotification({
      //   color: 'red',
      //   title: 'Agreement Form',
      //   message: 'Something went wrong.',
      // });
      setUiConfigs({
        ...uiConfigs,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
      return false;
    }
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.NO_ACTIVE_REQUEST,
    });
    return true;
  };

  const fetchAgreementData = async (agreementId) => {
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.LOADING,
    });

    const convertResponseToFormValues = (responseAgreement) => ({
      typeOfAgreement: responseAgreement.typeOfAgreement,
      titleOfAgreement: responseAgreement.titleOfAgreement,
      requestingUser: responseAgreement.requestingUserDetails,
      dateOfContract: responseAgreement.dateOfContract || null,
      location: responseAgreement.location || null,
      termOfContractInDays: responseAgreement.contractTermInDays || null,
      reminderTime: responseAgreement.reminderTime || null,
      contractQuillJsDelta: responseAgreement.contractQuillJsDelta || {
        ops: [{ insert: 'Agreement content goes here...' }],
      },
      firstParty: responseAgreement.firstParty || {},
      secondParty: responseAgreement.secondParty || {},
      thirdParty: responseAgreement.thirdParty || {},
      fourthParty: responseAgreement.fourthParty || {},
      fifthParty: responseAgreement.fifthParty || {},
      formCompleted: !!responseAgreement.formCompleted,
      noOfMonths: responseAgreement.agreementData.json.noOfMonths,
      noOfYears: responseAgreement.agreementData.json.noOfYears,
      // reminderTime: responseAgreement.agreementData.json.reminderTime,
      reminderTimeInDays: responseAgreement.reminderTimeInDays,
    });

    const response = await apiWrapWithErrorWithData(getAgreementDetails({ agreementId }));
    if (response?.success && response.agreement) {
      agreementForm.setValues((prevValues) => (flat({ ...initialState, ...flat.unflatten(prevValues), ...convertResponseToFormValues(response.agreement) })));
      setAgreementDetails({
        ...agreementDetails,
        agreementLoaded: true,
      });
      setUiConfigs({
        ...uiConfigs,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    } else {
      // showNotification({
      //   color: 'red',
      //   title: 'Agreement',
      //   message: 'Something went wrong.',
      // });
      setUiConfigs({
        ...uiConfigs,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    }
  };

  const getRegisterUser = async () => {
    const convertResponseForRequestingUser = (response) => ({
      requestingUser: response,
    });
    const res = await apiWrapWithErrorWithData(getRegisterUserApi());
    agreementForm.setValues((prevValues) => (flat({ ...initialState, ...flat.unflatten(prevValues), ...convertResponseForRequestingUser(res?.requestingUser) })));
  };

  useEffect(() => {
    if (agreementDetails.agreementId && !agreementDetails.agreementLoaded) {
      fetchAgreementData(agreementDetails.agreementId);
    }

    if (!agreementDetails.agreementId) {
      getRegisterUser();
    }
  }, [agreementDetails.agreementId, agreementDetails.agreementLoaded]);

  const getAgreementTypes = async () => {
    const resp = await apiWrapWithErrorWithData(getAgreementTypesApi());
    if (resp?.success && resp?.typesOfAgreement) {
      const uniqueValues = Array.from(new Set([
        ...resp.typesOfAgreement,
      ]));
      agreementForm.setFieldValue('typeOfAgreementData', uniqueValues);
    } else {
      // showNotification({
      //   title: 'Agreement Types',
      //   message: 'Could not load agreement types',
      //   color: 'red',
      // });
    }
  };

  const getAgreementTitle = async () => {
    const resp = await apiWrapWithErrorWithData(getAgreementTitleApi());
    if (resp?.success && resp?.titlesOfAgreement) {
      const uniqueValues = Array.from(new Set([
        ...resp.titlesOfAgreement,
      ]));
      agreementForm.setFieldValue('titleOfAgreementData', uniqueValues);
    } else {
      // showNotification({
      //   title: 'Agreement Title',
      //   message: 'Could not load agreement titles',
      //   color: 'red',
      // });
    }
  };

  useEffect(() => {
    getAgreementTypes();
    getAgreementTitle();
  }, []);

  const allowStepSelect = !!agreementForm.values.formCompleted;

  return (
    <Stepper
      color={themeColor(colors.agreement)}
      active={uiConfigs.currentStep}
      onStepClick={(step) => {
        setUiConfigs({
          ...uiConfigs,
          currentStep: step,
        });
      }}
      breakpoint="sm"
    >
    <Stepper.Step allowStepSelect={allowStepSelect} label="Requesting User">
      <AgreementForm1
        id={agreementDetails.agreementId}
        save={saveAgreement}
        uiConfigs={uiConfigs}
        setUiConfigs={setUiConfigs}
        form={agreementForm}
      />
    </Stepper.Step>
    <Stepper.Step allowStepSelect={allowStepSelect} label="Term & Location">
      <AgreementForm2
        id={agreementDetails.agreementId}
        save={saveAgreement}
        uiConfigs={uiConfigs}
        setUiConfigs={setUiConfigs}
        form={agreementForm}
        initialState={initialState}
      />
    </Stepper.Step>
    <Stepper.Step allowStepSelect={allowStepSelect} label="Parties details">
      <AgreementForm3
        id={agreementDetails.agreementId}
        save={saveAgreement}
        uiConfigs={uiConfigs}
        setUiConfigs={setUiConfigs}
        form={agreementForm}
        initialState={initialState}
      />
    </Stepper.Step>
    <Stepper.Step allowStepSelect={allowStepSelect} label="Templates">
      <AgreementForm4
        id={agreementDetails.agreementId}
        save={saveAgreement}
        uiConfigs={uiConfigs}
        setUiConfigs={setUiConfigs}
        form={agreementForm}
      />
    </Stepper.Step>
    </Stepper>
  );
};

export default AgreementCreatePage;
