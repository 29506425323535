import React, { useEffect, useState } from 'react';
import { Button, TextInput, Tooltip } from '@mantine/core';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import { BeatLoader } from 'react-spinners';
import 'quill/dist/quill.snow.css';
import ImageUploader from 'quill-image-uploader';
import { getValueForInput } from '../utilities/utilities';
import { Modal, Checkbox } from '@mantine/core';
import { createEditorField, deleteEditorField, getEditorField } from '../utilities/apis/agreements';
import { apiWrapWithErrorWithData } from '../utilities/apiHelpers';
import { useLocation } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import colors from '../utilities/design';
import { createTemplateEditorField, deleteTemplateEditorField, getTemplateEditorField } from '../utilities/apis/templates';

const Editor = ({ content, onContentChange, locked, onHtmlChange, showToolBar=true ,agreementId, templateId}) => {
  const [fieldData, setFieldData] = useState({
    text:'',
    value:'',
    placeholderValue:'select placeholder',
    showBtn: true,
    replaceValue:'',
    cursorPosition: null,
    valueOfInputField: '',
  })
  const { state } = useLocation();

  const [opened, setOpened] = useState(false);
  const [inputField,setInputField] = useState([]);
  const [formField,setFormField] =  useState([]);
  const [count, setCount] = useState(null);
  const [isMultiple, setIsMultiple] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [detArr, setDelArr] = useState([]);

  const changeHandler = (name) => (input) => {
    const value = getValueForInput(input);
    setFieldData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const { quill, quillRef, Quill} = useQuill({
    theme: 'snow',
    readOnly: locked,
    modules: {
      // cursors: true,
      clipboard: {},
      // toolbar: showToolBar ? toolbarOptions : false,
      toolbar: showToolBar ? '#toolbar' : false,
    },
    formats: ['bold','align','font','size','list','indent','underline','color','background','strike'],
  });

  if (Quill && !quill) {
    // const BlotFormatter = require('quill-blot-formatter');
    Quill.register('modules/blotFormatter', BlotFormatter);
    Quill.register("modules/imageUploader", ImageUploader);
    // Quill.register('modules/cursors', QuillCursors);
  }

  const getAgreementInputField = async () => {
    const formData = {
      agreementId:agreementId?agreementId:state?.agreementId
    }
    const response = await apiWrapWithErrorWithData(getEditorField(formData));
    if(response.success){
      if(response?.getFieldName?.fieldName !== undefined){
        console.log({ formField: response?.getFieldName?.fieldName });
        setFormField(response?.getFieldName?.fieldName);
      }
    }else{
      // showNotification(({
      //   color: 'red',
      //   title: 'Editor Field',
      //   message: 'Something went wrong.',
      // }));
    }
  }

  const saveAgreementInputField = async (inputField) => {
    const formData = {
      fieldName: [inputField],
      agreementId:agreementId?agreementId:state?.agreementId
    }
    const response = await apiWrapWithErrorWithData(createEditorField(formData));
    if(response?.success){
      getAgreementInputField()
    }else{
      // showNotification({
      //   message: 'Error in saving Editor Field.',
      //   title: 'Editor Field',
      //   color: 'red',
      // });
    }
  }

  const deleteAgreementInputField = async () => {
    setDeleteLoading(true);
    const formData = {
      fieldName:[...new Set(detArr)],
      agreementId:agreementId?agreementId:state?.agreementId
    }
    const response = await apiWrapWithErrorWithData(deleteEditorField(formData));
    if(response?.success){
      getAgreementInputField()
    }else{
      // showNotification({
      //   message: 'Error in saving Editor Field.',
      //   title: 'Editor Field',
      //   color: 'red',
      // });
    }
    setDeleteLoading(false);
    setConfirmationModal(false);
  }

  const getTemplateField = async () => {
    const formData = {
      templateId
    }
    const response = await apiWrapWithErrorWithData(getTemplateEditorField(formData));
    if(response.success){
      if(response?.getFieldName?.fieldName !== undefined){
        setFormField(response?.getFieldName?.fieldName)
      }
    }else{
      // showNotification(({
      //   color: 'red',
      //   title: 'Editor Field',
      //   message: 'Something went wrong.',
      // }));
    }
  }

  const saveTemplateField = async (inputField) => {
    const formData = {
      fieldName: inputField,
      templateId,
    }
    const response = await apiWrapWithErrorWithData(createTemplateEditorField(formData));
    if(response?.success){
      getTemplateField()
    }else{
      // showNotification({
      //   message: 'Error in saving Editor Field.',
      //   title: 'Editor Field',
      //   color: 'red',
      // });
    }
  }

  const deleteTemplateField = async () => {
    setDeleteLoading(true);
    const formData = {
      fieldName:[...new Set(detArr)],
      templateId,
    }
    const response = await apiWrapWithErrorWithData(deleteTemplateEditorField(formData));
    if(response?.success){
      getTemplateField()
    }else{
      // showNotification({
      //   message: 'Error in saving Editor Field.',
      //   title: 'Editor Field',
      //   color: 'red',
      // });
    }
    setDeleteLoading(false);
    setConfirmationModal(false);
  }
  
  // function getUsername() {
  //   // Random username 10 chars
  //   return DOUsername.generate(10);
  // }
  // function getCursorColor() {
  //   // random color for cursors
  //   var red = Math.floor(Math.random() * 255) + 1;
  //   var green = Math.floor(Math.random() * 255) + 1;
  //   var blue = Math.floor(Math.random() * 255) + 1;
  //   var alpha = Math.floor(Math.random() * 5) / 10 + 0.5;
  //   return "rgba(" + red + "," + green + "," + blue + "," + alpha + ")";
  // }
  // const getUserDetail = async ()=>{
  //   const response = await apiWrapWithErrorWithData(getUser());
  //   setUserData(response)
  // }

  useEffect(()=>{
    if(agreementId || state?.agreementId){
      getAgreementInputField()
    }
  },[])

  useEffect(()=>{
    if(templateId){
      getTemplateField()
    }
  },[])

  //  useEffect(()=>{
  //   if(quill){
  //     const ydoc = new Y.Doc()
  //     const ytext = ydoc.getText('quill')
  //     const provider = new WebrtcProvider('demo-room', ydoc)
  //     const binding = new QuillBinding(ytext, quill,provider.awareness)
  //     provider.awareness.setLocalStateField("user", {
  //       name: userData?.user?.name,
  //       color: getCursorColor()
  //     });      
  //   }
  //   },[quill])

  useEffect(() => {
    if (quill) {
      quill.setContents(content);
      quill.on('text-change', (delta, oldContents) => {
        onContentChange(quill.getContents());
        if(onHtmlChange){
          onHtmlChange(quill.root.innerHTML);
        }        
      });
    }
  }, [quill, Quill]);

  useEffect(() => {
    if(count !== null) {
      setOpened(true);
    }
  }, [count]);

  return (
    <div className='flex flex-row'>
    <div style={{height:'600px'}}>
    { showToolBar && (<div id="toolbar">
       <button className="ql-bold"></button>
       <button className="ql-underline"></button>
       <button className="ql-strike"></button>
       <select className="ql-align" />
       <select className="ql-size">
         <option value="small"></option>
         <option value="large"></option>
         <option value="huge"></option>
       </select>
       <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
        <select className="ql-color" />
        <select className="ql-background" />
        <button className="ql-table"></button> 
      <>
      <Modal
        opened={opened}
        onClose={() => {setOpened(false); setCount(null); }}
        size={300}
      >
        {/* <p><span>Selected Text:</span><span className='mx-2' style={{fontWeight: 'bold'}}>{quill?.getText(fieldData.cursorPosition?.index, fieldData.cursorPosition?.length)}</span></p> */}
        <div>
          <TextInput 
          placeholder="enter placeholder name"
          label="Text"
          value={fieldData.text}
          onChange={changeHandler('text')}
          />
          <Checkbox style={{ marginTop: '10px' }}
          label="is Select Multiple"
          checked={isMultiple}
          onChange={() => setIsMultiple(pre => !pre)}
        />
          {isMultiple && <p>{count} matches found</p>}
          <div className='flex justify-end mt-2'>
            <Button onClick={()=>{
            if(fieldData?.cursorPosition){
              if(isMultiple) {
                let repleaceableValue = quill.getText(fieldData.cursorPosition.index, fieldData.cursorPosition.length);
                var contents = quill?.root?.innerHTML;
                contents = contents.replaceAll(repleaceableValue, `<span style="background-color: rgb(255, 255, 0);">#${fieldData?.text.toLowerCase()}#</span>`);
                var delta = quill.clipboard.convert(contents);
                quill.setContents(delta, 'user');
              } else {
                quill.deleteText(fieldData.cursorPosition.index, fieldData.cursorPosition.length);
                quill.insertText(fieldData.cursorPosition.index,`#${fieldData?.text.toLowerCase()}#`,{'background':'#ffff00'});
              }
              
              if(agreementId || state?.agreementId){
                setInputField([...inputField,fieldData?.text.replaceAll('#','').toLowerCase()]);
                saveAgreementInputField(fieldData?.text.replaceAll('#','').toLowerCase());
              }
              if(templateId){
                setInputField([...inputField,fieldData?.text.replaceAll('#','').toLowerCase()]);
                saveTemplateField(fieldData?.text.replaceAll('#','').toLowerCase());
              }
            }
            setFieldData({...fieldData,text:''})
            setOpened(false);
            setCount(null);
          }}>Add</Button></div>
        </div>
      </Modal>
        <button onClick={()=>{
          const cursorPosition = quill.getSelection(focus);
          let repleaceableValue = quill.getText(cursorPosition.index, cursorPosition.length);
          var contents = quill?.root?.innerHTML;
          let countVal = 0;
          contents.replaceAll(repleaceableValue, () => countVal++);
          setCount(countVal);
          setFieldData({...fieldData,cursorPosition:quill.getSelection(focus)});
        }} className='mr-3' name="text">CreatePlaceholder</button>
      </>
       </span>
       <select className="ql-font">
       <option  value="sans-serif" />
       <option  value="serif" />
       <option  value="monospace" />
       </select>
       <button className="ql-image"></button>
    </div>)}
   <div ref={quillRef} 
   />
 </div>
 <div className='ml-4'>
 <div>
    <div className='flex flex-col'>
      {formField?.map((input,index)=>{
        return (
        <div  
         key={index}
         className="border-2 border-none rounded-lg p-4"
         style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) '}}
        >
          <Tooltip  className='flex justify-end'
          label="remove input field"
          position="top"
          offset={-10}
          withArrow
          >
            <div
              className='flex justify-end'
              style={{cursor:'pointer'}}
              onClick={()=> {
                const newArray = formField.filter((_,i) => index !== i);
                setDelArr(newArray);
                setConfirmationModal(true);
              }}>x</div>
          </Tooltip>
          <TextInput
            className='mb-2'
            item={`#${input.toLowerCase()}#`}
            label={`ENTER ${input.toUpperCase()}`}
            placeholder={`${input.toLowerCase()}`} 
            onChange={(e)=>{
              setFieldData({...fieldData,replaceValue:`#${e.target.placeholder}#`,valueOfInputField:e.target.value});
              }
            }
          />
          <div>
            <Button 
              onClick={()=>{
                if(fieldData.valueOfInputField !== ''){
                  var contents = quill?.root?.innerHTML;
                  contents = contents.replaceAll(`${fieldData.replaceValue}`,fieldData.valueOfInputField);
                  setFieldData({...fieldData,valueOfInputField:''});
                  var delta = quill.clipboard.convert(contents);
                  quill.setContents(delta, 'user');
                }
              }}
            >Add</Button>
          </div>
        </div>)
      })}
      {/* {userData?.user && insert && (
        <div 
        className="border-2 border-none rounded-lg p-4"
        style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) '}}
        >
        <div className="ml-2 flex flex-col">
          <div className="flex flex-row justify-between">
            <Text size="md">{userData?.user?.name}</Text>
            <div 
            style={{cursor:'pointer'}}
            onClick={()=>{
            }}>✓</div>
            <div 
             style={{cursor:'pointer'}}
            >x</div>
          </div>
          <div>{`insert: "${insertText}"`}</div>
        </div>
        <div></div>
    </div>
      )} */}


      {/* {userData?.user && del && (
        <div 
        className="border-2 border-none rounded-lg p-4"
        style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) '}}
        >
        <div className="ml-2 flex flex-col">
          <div className="flex flex-row justify-between">
            <Text size="md">{userData?.user?.name}</Text>
            <div 
            style={{cursor:'pointer'}}
            onClick={()=>{
            }}>✓</div>
            <div 
             style={{cursor:'pointer'}}
            >x</div>
          </div>
          <div>{`remove: "${deleteText}"`}</div>
        </div>
        <div></div>
    </div>
      )} */}
      
    </div>
   </div>
 </div>
  <Modal
    overflow="inside"
    closeOnClickOutside={false}
    size="calc(30vw)"
    opened={confirmationModal}
    onClose={() => setConfirmationModal(!confirmationModal)}
    title="Delete place holder"
  >
    <div className="my-2">
      <h4>Are you sure you want to delete this place holder?</h4>
      <div className="flex flex-row justify-end">
        <Button
          style={{
            backgroundColor: colors.primary,
            borderRadius: '0.5rem',
            color: '#F5F5F5',
          }}
          onClick={() => setConfirmationModal(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={deleteLoading}
          className="ml-4"
          style={{
            backgroundColor: deleteLoading ? '#ffcccc' : '#ff4d4d',
            borderRadius: '0.5rem',
            color: '#F5F5F5',
          }}
          onClick={() => {
            (agreementId || state?.agreementId) ? deleteAgreementInputField() : null;
            (templateId) ? deleteTemplateField() : null;
          }}
        >
          {deleteLoading ? <BeatLoader size={10} color={colors.rejected} /> : 'Delete'}
        </Button>
      </div>
    </div>
  </Modal>
 </div>
  );
};

export default Editor;