import React, { useState, useEffect } from 'react';
import { Modal, Anchor } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { getCaseRecordDetails } from '../../utilities/apis/cases';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';

function CaseRecordSummary({ id }) {
  const [opened, setOpened] = useState(false);
  const [summary, setSummary] = useState();

  const getRecordDetails = async () => {
    const response = await apiWrapWithErrorWithData(
      getCaseRecordDetails({
        caseRecordId: id,
      }),
    );
    if (response.success) {
      setSummary(response.caseRecord.summary);
    } else {
      // showNotification({
      //   color: 'red',
      //   title: 'Case records',
      //   message: 'Failed to load records summary',
      // });
    }
  };

  useEffect(() => {
    getRecordDetails();
  }, [id]);
  return (
    <div>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Summary"
      >
        {summary}
      </Modal>
      <Anchor onClick={() => setOpened(true)}>view</Anchor>

    </div>
  );
}

export default CaseRecordSummary;
