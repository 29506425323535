/** @format */

import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showNotification } from "@mantine/notifications";
import { Table, Skeleton, Pagination, Button, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";

import { getAllBlogs, deleteBlog } from "../../utilities/apis/blogs";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { selectUserData } from "../../redux/selectors";

function Blogs() {
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);

  const [blogs, setBlogs] = useState();
  const [uiConfigs, setUiConfigs] = useState({
    pageLoading: false,
    page: 1,
  });

  const fetchBlogs = async () => {
    setUiConfigs((preState) => ({ ...preState, pageLoading: true }));
    const resp = await apiWrapWithErrorWithData(
      getAllBlogs({ page: uiConfigs.page })
    );
    if (resp?.success) {
      setBlogs(resp);
    } else {
      // showNotification({
      //   color: "red",
      //   title: "Blogs",
      //   message: "Something went wrong.",
      // });
    }
    setUiConfigs((preState) => ({ ...preState, pageLoading: false }));
  };

  useEffect(() => {
    fetchBlogs();
  }, [uiConfigs.page]);

  const onDelete = async (id) => {
    const resp = await apiWrapWithErrorWithData(deleteBlog({ blogId: id }));
    if (resp?.success) {
      fetchBlogs();
      // navigate("/app/blog-manager");
      showNotification({
        color: "green",
        title: "Blogs",
        message: `Blog is deleted`,
      });
    }
  };

  return (
    <div>
      {uiConfigs.pageLoading ? (
        Array.from({ length: 10 }, (_, i) => i).map(() => (
          <Skeleton height={30} radius="md" className="my-1 w-full" />
        ))
      ) : (
        <div>
          <div className="flex justify-between my-4">
            <Text>Blogs</Text>
            <Button
              onClick={() => {
                navigate("/app/blog-manager/create");
              }}
            >
              Create Blog
            </Button>
          </div>
          <div className="flex flex-col">
            <Table striped>
              <thead>
                <tr>
                  <th>Reference No</th>
                  <th>Title</th>
                  <th>Created By</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {blogs?.data.map(({ id, title, createdBy }) => (
                  <tr key={id}>
                    <td>{id}</td>
                    <td>{title}</td>
                    <td>{createdBy.name}</td>
                    <td>
                      {/* <Button onClick={() => navigate(`/app/blog-manager/edit/${id}`)}>Edit</Button> */}
                      {userData?.designation === 'Admin' ? <Button
                        style={{ backgroundColor: "red", color: "white" }}
                        onClick={() => onDelete(id)}
                      >
                        Delete
                      </Button> : null }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {blogs?.blogsCount ? (
            <>
              <div className="flex flex-row justify-center my-4">
                <Pagination
                  page={uiConfigs.page}
                  onChange={(page) => {
                    setUiConfigs({
                      ...uiConfigs,
                      page,
                    });
                  }}
                  total={Math.ceil(blogs.blogsCount / 10)}
                />
              </div>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default Blogs;
