/* eslint-disable */
import React from 'react';
import { Button, Select, TextInput } from '@mantine/core';
import flat from 'flat';
import { BeatLoader } from 'react-spinners';
import { DatePicker } from '@mantine/dates';
import { formatISO, parseISO } from 'date-fns';
import { loadingStates } from '../../utilities/utilities';
import colors from '../../utilities/design';
import { showNotification } from '@mantine/notifications';

export const INDIA_STATES_UNIONS = [
  { label: 'Andhra Pradesh', value: 'Andhra Pradesh' },
  { label: 'Karnataka', value: 'Karnataka' },
  { label: 'Andaman and Nicobar Islands', value: 'Andaman and Nicobar Islands' },
  { label: 'Arunachal Pradesh', value: 'Arunachal Pradesh' },
  { label: 'Assam', value: 'Assam' },
  { label: 'Bihar', value: 'Bihar' },
  { label: 'Chandigarh', value: 'Chandigarh' },
  { label: 'Chattisgarh', value: 'Chattisgarh' },
  { label: 'Dadra and Nagar Haveli', value: 'Dadra and Nagar Haveli' },
  { label: 'Daman and Diu', value: 'Daman and Diu' },
  { label: 'Delhi', value: 'Delhi' },
  { label: 'Goa', value: 'Goa' },
  { label: 'Gujarat', value: 'Gujarat' },
  { label: 'Haryana', value: 'Haryana' },
  { label: 'Himachal Pradesh', value: 'Himachal Pradesh' },
  { label: 'Jammu and Kashmir', value: 'Jammu and Kashmir' },
  { label: 'Jharkhand', value: 'Jharkhand' },
  { label: 'Kerala', value: 'Kerala' },
  { label: 'Lakshadweep Islands', value: 'Lakshadweep Islands' },
  { label: 'Madhya Pradesh', value: 'Madhya Pradesh' },
  { label: 'Maharashtra', value: 'Maharashtra' },
  { label: 'Manipur', value: 'Manipur' },
  { label: 'Meghalaya', value: 'Meghalaya' },
  { label: 'Mizoram', value: 'Mizoram' },
  { label: 'Nagaland', value: 'Nagaland' },
  { label: 'Odisha', value: 'Odisha' },
  { label: 'Pondicherry', value: 'Pondicherry' },
  { label: 'Punjab', value: 'Punjab' },
  { label: 'Rajasthan', value: 'Rajasthan' },
  { label: 'Sikkim', value: 'Sikkim' },
  { label: 'Tamil Nadu', value: 'Tamil Nadu' },
  { label: 'Telangana', value: 'Telangana' },
  { label: 'Tripura', value: 'Tripura' },
  { label: 'Uttar Pradesh', value: 'Uttar Pradesh' },
  { label: 'Uttarakhand', value: 'Uttarakhand' },
  { label: 'West Bengal', value: 'West Bengal' },
];

const AgreementForm2 = ({
    id, form, setUiConfigs, uiConfigs, save, initialState
}) => {
  const agreementFormJson = flat.unflatten(form.values);
  console.log({ agreementFormJson });

  const reminderLabels = {
    15:'15 days',
    30:'30 days',
    45:'45 days',
    60:'60 days',
  };

  const validate = () => {
    const keys = {};
      if (!agreementFormJson.dateOfContract
          || isNaN(parseISO(
              agreementFormJson.dateOfContract
          ).getTime())) {
        keys['dateOfContract'] = 'Please enter date.';
      }

      if(!agreementFormJson.location) {
        keys['location'] = 'Please enter location.';
      }
     
      if((!agreementFormJson.noOfYears || isNaN(agreementFormJson.noOfYears) || parseInt(agreementFormJson.noOfYears, 10) <= 0) && (!agreementFormJson.noOfMonths || isNaN(agreementFormJson.noOfMonths) || parseInt(agreementFormJson.noOfMonths, 10) <= 0)){
        keys['noOfYears'] = 'Please check value.';
        keys['noOfMonths'] = 'Please check value.';
      }
      // if(isNaN(agreementFormJson.termOfContractInDays) || agreementFormJson.termOfContractInDays <=0){
      //   keys['termOfContractInDays'] = 'Please check value.';
      // }

      // if(isNaN(agreementFormJson.noOfMonths)){
      //   keys['noOfMonths'] = 'Please check value.';
      // }
      
      // if(!agreementFormJson.termOfContractInDays){
      //   keys['termOfContractInDays'] = 'Please check value.';
      // }
    // if (!agreementFormJson.reminderTimeInDays) {
    //     keys[`reminderTimeInDays`] = 'Please enter no. of days.';
    // }

    if((Object.keys(keys)).length !== 0){
      form.setErrors(keys);
      showNotification(({
        color: 'red',
        title: 'Agreement Form',
        message: 'Please check all fields are filled properly.',
      }));
    }
    return (Object.keys(keys)).length === 0;

  };

  const savePrev = async () => {
    if (validate()) {
      await save({...form.values });
      setUiConfigs({
        ...uiConfigs,
        currentStep: 0,
      });
    }
  };

  const saveNext = async () => {
    if (validate()) {
      await save({...form.values });
      setUiConfigs({
        ...uiConfigs,
        currentStep: 2,
      });
      // navigate(`/app/agreements/details/${id}`);
    }
  };

  return (
    <div className="w-full flex flex-col my-4">
      <div style={{ width: '50%', marginLeft: '5%'}}>
        <DatePicker
          required
          style={{ marginBottom: '20px'}}
          label="Date of contract"
          placeholder="Select date"
          {...({
            ...form.getInputProps('dateOfContract'),
            onChange: (val) => {
            if (val && val.getTime) {
              form.getInputProps('dateOfContract').onChange(
                  formatISO(val),
              );
            } else {
              form.getInputProps('dateOfContract').onChange(
                  null,
              );
            }
            },
            value:
              form.values['dateOfContract']
                  ? parseISO(form.values.dateOfContract) : null,
          })}
        />
        <Select
          style={{ marginBottom: '20px'}}
          placeholder="Reminder time"
          label="Renewal Reminder Time (in days)"
          data={[
            { value: 15, label: reminderLabels['15'] },
            { value: 30, label: reminderLabels['30'] },
            { value: 45, label: reminderLabels['45'] },
            { value: 60, label: reminderLabels['60'] },
          ]}
          {...form.getInputProps('reminderTimeInDays')}
        />
        <div className='flex flex-row' style={{ marginBottom: '20px'}}>
          <TextInput label="Contract term" placeholder='years' {...form.getInputProps('noOfYears')} style={{width:'100px', marginRight: '20px'}}/>
          <TextInput label=" " placeholder='months' {...form.getInputProps('noOfMonths')} style={{width:'80px'}}/>
        </div>
        <Select
          required
          searchable
          placeholder="Enter location"
          label="Location"
          data={[...INDIA_STATES_UNIONS]}
          {...form.getInputProps('location')}
        />
        {/* <TextInput required label="Location" placeholder='Enter location' {...form.getInputProps('location')}/> */}
      </div>
      <div className="flex flex-row justify-between">
          {uiConfigs.loading === loadingStates.LOADING
          ? <BeatLoader color={colors.primary} size={10} />
          : (
              <>
              <Button className="max-w-xs mt-20" onClick={savePrev} style={{
                    backgroundColor: '#46BDE1',
                }}>Previous</Button>
                    <Button className="max-w-xs mt-20" onClick={saveNext} style={{
                    backgroundColor: '#46BDE1',
                }}>Next</Button>
              </>
          )}
      </div>
    </div>
  );
};

export default AgreementForm2;
