/** @format */

import React, { useEffect, useState } from "react";
import { Button } from "@mantine/core";
import flat from "flat";
import { BeatLoader } from "react-spinners";
import { loadingStates, validateEmail } from "../../utilities/utilities";
import colors from "../../utilities/design";
import { showNotification } from "@mantine/notifications";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getPartiesList } from "../../utilities/apis/parties";
import AgreementParty from "./AgreementParty";
import userPartyCreateHook from "../../hooks/usePartyCreate";

const AgreementForm3 = ({
  id,
  form,
  setUiConfigs,
  uiConfigs,
  save,
  initialState,
}) => {
  const agreementFormJson = flat.unflatten(form.values);

  const [dropDownNames, setDropDownNames] = useState([]);
  const [parties, setParties] = useState([]);
  const [addParty, setAddParty] = useState(0);

  console.log({ parties });

  userPartyCreateHook("firstParty", form, parties, initialState);
  userPartyCreateHook("secondParty", form, parties, initialState);
  userPartyCreateHook("thirdParty", form, parties, initialState);
  userPartyCreateHook("fourthParty", form, parties, initialState);
  userPartyCreateHook("fifthParty", form, parties, initialState);

  const partiesApi = async (name) => {
    const { data: res } = await apiWrapWithErrorWithData(
      getPartiesList({ name })
    );
    setParties(res);
    const names = res.map(({ name: val }) => val);
    setDropDownNames(names);
  };

  useEffect(() => {
    if (Object.keys(agreementFormJson.fifthParty).length) {
      setAddParty(3);
    } else if (Object.keys(agreementFormJson.fourthParty).length) {
      setAddParty(2);
    } else if (Object.keys(agreementFormJson.thirdParty).length) {
      setAddParty(1);
    }

    partiesApi();
  }, []);

  const validate = () => {
    const keys = {};
    if (
      !agreementFormJson.firstParty.name ||
      agreementFormJson.firstParty.name?.length < 3
    ) {
      keys["firstParty.name"] = "Please enter 3 or more characters.";
    }
    if (!validateEmail(agreementFormJson.firstParty?.email)) {
      keys["firstParty.email"] = "Please enter correct email.";
    }

    if (
      !agreementFormJson.secondParty.name ||
      agreementFormJson.secondParty.name?.length < 3
    ) {
      keys["secondParty.name"] = "Please enter 3 or more characters.";
    }
    if (!validateEmail(agreementFormJson.secondParty?.email)) {
      keys["secondParty.email"] = "Please enter correct email.";
    }

    if (Object.keys(keys).length !== 0) {
      form.setErrors(keys);
      showNotification({
        color: "red",
        title: "Agreement Form",
        message: "Please check all fields are filled properly.",
      });
    }
    return Object.keys(keys).length === 0;
  };

  const savePrev = async () => {
    if (validate()) {
      await save({ ...form.values, formCompleted: true });
      setUiConfigs({
        ...uiConfigs,
        currentStep: 1,
      });
    }
  };

  const saveNext = async () => {
    if (validate()) {
      await save({ ...form.values, formCompleted: true });
      setUiConfigs({
        ...uiConfigs,
        currentStep: 3,
      });
    }
  };

  return (
    <div className="w-full flex flex-col my-4">
      <div className="flex flex-col justify-between my-2">
        <AgreementParty
          agreementFormJson={agreementFormJson}
          dropDownNames={dropDownNames}
          form={form}
          partyName={"firstParty"}
          setDropDownNames={setDropDownNames}
        />
        <AgreementParty
          agreementFormJson={agreementFormJson}
          dropDownNames={dropDownNames}
          form={form}
          partyName={"secondParty"}
          setDropDownNames={setDropDownNames}
        />
        {[1, 2, 3].includes(addParty) ? (
          <AgreementParty
            agreementFormJson={agreementFormJson}
            dropDownNames={dropDownNames}
            form={form}
            partyName={"thirdParty"}
            setDropDownNames={setDropDownNames}
          />
        ) : null}
        {[2, 3].includes(addParty) ? (
          <AgreementParty
            agreementFormJson={agreementFormJson}
            dropDownNames={dropDownNames}
            form={form}
            partyName={"fourthParty"}
            setDropDownNames={setDropDownNames}
          />
        ) : null}
        {[3].includes(addParty) ? (
          <AgreementParty
            agreementFormJson={agreementFormJson}
            dropDownNames={dropDownNames}
            form={form}
            partyName={"fifthParty"}
            setDropDownNames={setDropDownNames}
          />
        ) : null}
      </div>
      <div className="flex justify-end">
        {addParty < 3 ? (
          <Button onClick={() => setAddParty((pre) => pre + 1)}>
            Add Party
          </Button>
        ) : null}
      </div>
      <div className="flex flex-row justify-between">
        {uiConfigs.loading === loadingStates.LOADING ? (
          <BeatLoader color={colors.primary} size={10} />
        ) : (
          <>
            <Button
              className="max-w-xs mt-20"
              onClick={savePrev}
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              Previous
            </Button>
            <Button
              className="max-w-xs mt-20"
              onClick={saveNext}
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              Next
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AgreementForm3;
