/** @format */
import { Doughnut } from 'react-chartjs-2';
import { totalCountChart } from "../../utilities/utilities";

const DoughnutChart = ({ data, method, chartOptions }) => (
  <>
    {data.length ? (
      <>
        <div>
          <span>Total: <span style={{ fontWeight: 'bold'}}>{totalCountChart(data)}</span></span>
        </div>
        <Doughnut data={method(data)} options={chartOptions} />
      </>
    ) : (
      <p style={{ textAlign: "center" }}>No data</p>
    )}
  </>
);

export default DoughnutChart;
