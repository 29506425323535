import axios from 'axios';
import constants from '../constants';
import { headersProvider } from '../apiHelpers';

export const addTemplate = ({
  type,
  name,
  category,
  html,
}) => axios.post(`${constants.utilityHost}/templates/create`, {
  type,
  name,
  category,
  html,
}, {
  headers: headersProvider(),
});

export const templatesListApi = ({
  page,
  type,
  category,
}) => axios.get(`${constants.utilityHost}/templates/list`, {
  params: {
    page,
    type,
    category,
  },
  headers: headersProvider(),
});

export const templatesDetailsApi = ({
  templateId,
}) => axios.get(`${constants.utilityHost}/templates/details/${templateId}`, {
  headers: headersProvider(),
});

export const templatesUpdateEditorContent = ({
  templateId, quillDelta,
}) => axios.put(`${constants.utilityHost}/templates/editor-update/${templateId}`, { quillDelta }, {
  headers: headersProvider(),
});

export const createTemplateEditorField = ({ fieldName, templateId }) => axios.post(`${constants.utilityHost}/templates/editor-field/${templateId}`, { fieldName },
  {
    headers: headersProvider(),
  });
export const deleteTemplateEditorField = ({ fieldName, templateId }) => axios.put(`${constants.utilityHost}/templates/editor-field/${templateId}`, { fieldName },
  {
    headers: headersProvider(),
  });

export const getTemplateEditorField = ({ templateId }) => axios.get(`${constants.utilityHost}/templates/editor-field/${templateId}`,
  {
    headers: headersProvider(),
  });

export const downloadCsvApi = ({
  templateId,
}) => axios.get(`${constants.utilityHost}/templates/csv-download/${templateId}`, {
  headers: headersProvider(),
});

export const deleteTemplate = ({
  templateId,
}) => axios.delete(`${constants.utilityHost}/templates/delete/${templateId}`, {
  headers: headersProvider(),
});

