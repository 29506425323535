import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import {
  Badge, Button, Modal, Skeleton, Tabs, Text, Anchor, Group, Select, ActionIcon,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { differenceInDays, formatDistanceStrict } from 'date-fns';
// import { Check, CloudUpload } from 'tabler-icons-react';
import { BeatLoader } from 'react-spinners';
import { Edit } from 'tabler-icons-react';
import {
  formatDate, formatTime, getValueForInput, loadingStates,
} from '../../utilities/utilities';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';
import { fetchNoticeDetails, updateNotice } from '../../utilities/apis/notices';
import styles from '../../components/NoticeDetail/NoticeDetail.module.css';
import { noticeStatusColors, noticeStatusValues } from '../../utilities/enums';
import AddressRenderer from '../../components/CaseDetails/AddressRenderer';
import { getPreviewUrl } from '../../utilities/apis/agreements';
import colors from '../../utilities/design';
import Editor from '../../components/Editor';
import NewDocumentUploader from '../../components/NewDocumentUploader/NewDocumentUploader';
import TasksTab from '../../components/TasksTab/TasksTab';
import useMultiFileUpload from '../../hooks/useMultiFileUpload';
import EmailTab from '../../components/EmailTab/EmailTab';
import ExpensesTab from '../../components/ExpensesTab/ExpensesTab';
import TemplatesList from '../../components/TemplateManager/TemplatesList';
import { deleteNotices } from '../../utilities/apis/notices';
import { selectUserData } from '../../redux/selectors';
// import EmailBox from '../../components/EmailBox';

const NoticeDetailsPage = () => {
  const { noticeId: noticeIdFromUrl } = useParams();
  const [state, setState] = useState(false);
  const [uiConfigs, setUiConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    activeTab: 'notice',
    loadingContract: loadingStates.NO_ACTIVE_REQUEST,
    previewPdfLoading: loadingStates.NO_ACTIVE_REQUEST,
    lastSaved: new Date(),
    lastSavedText: (formatDistanceStrict(new Date(), new Date(), {
      addSuffix: true,
    })),
    emailBoxView: false,
  });

  const [configs, setConfigs] = useState({
    noticeDetails: null,
    noticeStatus: '',
    noOfCompletionDays: '',
  });

  const onValueChange = (name) => (incoming) => {
    const value = getValueForInput(incoming);
    setConfigs((settings) => ({
      ...settings,
      [name]: value,
    }));
  };

  const [contractHtml, setContractHtml] = useState(null);
  const [debouncedContractHtml] = useDebouncedValue(contractHtml, 1000, { leading: true });

  const openPreviewUrl = async () => {
    setUiConfigs({ ...uiConfigs, previewPdfLoading: loadingStates.LOADING });
    const { url } = await getPreviewUrl(contractHtml).catch(() => {
      showNotification({
        color: 'red',
        message: 'Could not load preview.',
        title: 'PDF Preview',
      });
      setUiConfigs({ ...uiConfigs, previewPdfLoading: loadingStates.NO_ACTIVE_REQUEST });
    });
    if (url && url.length) {
      window.open(url, '_blank').focus();
    } else {
      showNotification({
        color: 'red',
        message: 'Could not load preview.',
        title: 'PDF Preview',
      });
    }
    setUiConfigs({ ...uiConfigs, previewPdfLoading: loadingStates.NO_ACTIVE_REQUEST });
  };

  const fetchNotice = async (noticeId) => {
    setUiConfigs((stateC) => ({
      ...stateC,
      loading: loadingStates.LOADING,
    }));
    const resp = await apiWrapWithErrorWithData(fetchNoticeDetails({ noticeId }));
    if (resp?.success) {
      setConfigs((stateC) => ({
        ...stateC,
        noticeDetails: {
          noticeId: resp.notice.id,
          notice: resp.notice,
          noticeData: resp.notice.noticeData,
        },
        noticeStatus: resp.notice.status,
        // setDays(differenceInDays(new Date(resp?.case.completionDate),
        // new Date(resp?.case.createdAt)));
        noOfCompletionDays: differenceInDays(new Date(resp.notice.completionDate),
          new Date(resp?.notice.createdAt)),
      }));
      setState(true);
      setContractHtml(resp.notice?.noticeData?.json?.contractHtml || '<p>Agreement information goes here.</p>');
      setUiConfigs((stateC) => ({
        ...stateC,
        noticeLoaded: true,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      }));
    } else {
      setUiConfigs((stateC) => ({
        ...stateC,
        noticeLoaded: true,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      }));
      // showNotification({
      //   color: 'red',
      //   title: 'Notice Details',
      //   message: 'Something went wrong.',
      // });
    }
  };

  // useEffect(() => {
  //   fetchNotice();
  // }, []);

  const notice = configs.noticeDetails?.notice;
  const noticeData = configs.noticeDetails?.noticeData?.json;
  const noticeId = configs.noticeDetails?.noticeId;
  // const noticeStatus = configs.noticeDetails?.noticeStatus;

  useEffect(() => {
    let interval = null;
    if (uiConfigs.activeTab === 'notice') {
      interval = setInterval(() => {
        setUiConfigs((stateC) => ({
          ...stateC,
          lastSavedText: (formatDistanceStrict(stateC.lastSaved, new Date(), {
            addSuffix: true,
          })),
        }));
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [uiConfigs.activeTab]);

  const [configs2, setConfigs2] = useState({
    noticeContent: '',
    selectedFile: null,
  });

  const multiUploadArgs = useMultiFileUpload({
    parent: 'notice',
    parentId: noticeIdFromUrl,
    // pdf
    loadFromParent: true,
    onFileCheck: (file, files) => {
      const checkedFiles = files.filter((currFile) => currFile.checked);
      if (checkedFiles.length) {
        showNotification({
          title: 'Notice File',
          message: 'Select only one file.',
          color: 'red',
        });
        return false;
      }
      if (file.extension.toLowerCase() === 'pdf') {
        setConfigs2((prevState) => ({
          ...prevState,
          selectedFile: ({
            fileId: file.fileId || null,
            fileName: file.fileName,
            extension: file.extension,
            mimeType: file.mimeType,
            hostedLink: file.url,
          }),
          noticeContent: 'file',
        }));
        return true;
      }
      showNotification({
        title: 'Notice File',
        message: 'Select a PDF file',
        color: 'red',
      });
      return false;
    },
  });

  // useEffect(() => {
  //   if (configs2.noticeContent !== 'file' && configs2.selectedFile) {
  //     setConfigs2((prevState) => ({
  //       ...prevState,
  //       selectedFile: null,
  //     }));
  //     multiUploadArgs.unCheckAllFiles();
  //   }
  // }, [configs2.noticeContent]);

  // let valid = false;
  // if (configs2.noticeContent === 'file') {
  //   valid = !!configs.selectedFile?.fileName;
  // }

  const [configs1, setConfigs1] = useState({
    templateView: false,
    editorKey: 'key-1',
    // editorContent: agreementFormJson.contractQuillJsDelta || ({
    //   // ops: [{insert: "Agreement content goes here..."}]
    // }),
    editorContent: '',
  });

  const saveNoticeContract = async (html) => {
    if (!noticeData) {
      return;
    }
    setUiConfigs((stateC) => ({
      ...stateC,
      loadingContract: loadingStates.LOADING,
    }));
    const resp = await apiWrapWithErrorWithData(updateNotice({
      noticeId,
      noticeStatus: configs.noticeStatus,
      noOfCompletionDays: configs.noOfCompletionDays,
      noticeData: ({
        ...notice.noticeData,
        flatted: {
          ...notice.noticeData.flatted,
          contractHtml: html,
        },
        json: {
          ...notice.noticeData.json,
          contractHtml: html,
        },
      }),
    }));
    if (resp && resp.success) {
      setUiConfigs((stateC) => ({
        ...stateC,
        loadingContract: loadingStates.NO_ACTIVE_REQUEST,
        lastSaved: new Date(),
        lastSavedText: (formatDistanceStrict(new Date(), new Date(), {
          addSuffix: true,
        })),
      }));
    } else {
      setUiConfigs((stateC) => ({
        ...stateC,
        loadingContract: loadingStates.NO_ACTIVE_REQUEST,
      }));
      showNotification({
        message: 'Error in saving notice data.',
        title: 'Notice',
        color: 'red',
      });
    }
  };
  const navigate = useNavigate();
  // const [opened, setOpened] = useState(false);
  useEffect(() => {
    saveNoticeContract(debouncedContractHtml);
  }, [debouncedContractHtml]);

  useEffect(() => {
    if (noticeIdFromUrl) {
      fetchNotice(noticeIdFromUrl);
    } else {
      navigate('/app');
    }
  }, []);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const userDetails = useSelector(selectUserData);

  const deleteHandler = async () => {
    setDeleteLoading(true);
    const response = await apiWrapWithErrorWithData(deleteNotices({ noticeId: noticeIdFromUrl }));
    if (response?.success) {
      showNotification({
        color: 'green',
        title: 'Notices',
        message: 'Notice deleted successfully.',
      });
      setOpen(false);
      navigate('/app/dispute-manager/legal-notices');
    } else {
      showNotification({
        color: 'red',
        title: 'Notices',
        message: 'Unable to delete Notice',
      });
    }
    setDeleteLoading(false);
  };

  return (
    <>
      <div className="flex flex-col">
        {uiConfigs.loading === loadingStates.LOADING
          && (
          <div className="flex flex-col mb-2 mt-8">
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={60} radius="md" className="mt-4 w-full" />
            <Skeleton height={60} radius="md" className="mt-4 w-full" />
            <Skeleton height={60} radius="md" className="mt-4 w-full" />
            <Skeleton height={60} radius="md" className="mt-4 w-full" />
          </div>
          )}
        {(!!configs.noticeDetails && uiConfigs.loading !== loadingStates.LOADING)
            && (
            <div className="flex flex-col pt-7 pl-4 pr-7">
              <div className='flex justify-between'>
                <div className={styles.title}>{notice.noticeTitle}</div>
                <div className="flex flex-row justify-between">
                  <Button
                    className="ml-4"
                    style={{
                      backgroundColor: '#46BDE1',
                      borderRadius: '0.5rem',
                      color: '#F5F5F5',
                    // width: '180px',
                    }}
                    onClick={() => {
                      navigate('/app/dispute-manager/legal-notices/edit', {
                        state: {
                          noticeId,
                        },
                      });
                    }}
                  >
                    Edit
                  </Button>
                  {userDetails?.designation === 'Admin' ? (
                    <Button
                      className="ml-4"
                      style={{
                        backgroundColor: '#ff4d4d',
                        borderRadius: '0.5rem',
                        color: '#F5F5F5',
                        // width: '180px',
                      }}
                      onClick={() => setOpen(true)}
                    >
                      Delete
                    </Button>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="flex flex-row">
                  <div className="flex flex-row mr-4">
                    <img className="pr-2" src="/assets/images/calendar.svg" alt="calendar" />
                    <span className={styles.dateTime}>
                      Created on
                      {' '}
                      {formatDate(notice.createdAt)}
                    </span>
                  </div>
                  <div className="flex flex-row mr-4">
                    <img className="pr-2" src="/assets/images/clock.svg" alt="clock" />
                    <span className={styles.dateTime}>
                      {' '}
                      {formatTime(notice.createdAt)}
                    </span>
                  </div>
                </div>
                <div>
                  {!state && (
                  <div className="flex flex-row">
                    <Select
                     // style={{ width: '160px' }}
                      placeholder="Pick one"
                      data={noticeStatusValues}
                      value={configs.noticeStatus}
                      onChange={onValueChange('noticeStatus')}
                    />
                    <Button
                    //  onClick={saveCase}
                      onClick={() => {
                        saveNoticeContract();
                        setState(true);
                      }}
                      style={{ width: '75px' }}
                      className="w-24"
                    >
                      Save
                    </Button>
                  </div>
                  )}
                  {state && (
                  // <Badge size="lg" color={noticeStatusColors[notice.status] || 'orange'}>
                  //   {notice.status}
                  // </Badge>
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center">
                      <Badge size="lg" color={noticeStatusColors[configs.noticeStatus] || 'orange'}>
                        {/* {notice.status} */}
                        {configs.noticeStatus}
                      </Badge>
                      <ActionIcon>
                        <Edit
                          size={24}
                          onClick={() => {
                            setState(false);
                          }}
                        />
                      </ActionIcon>
                    </div>
                    {configs.noticeStatus !== 'COMPLETED' && (
                    <div className="mt-2">
                      pending since
                      {' '}
                      {configs.noOfCompletionDays}
                      {' '}
                      days
                    </div>
                    )}
                  </div>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <div className={styles.title}>Sender</div>
                {/* <SenderDetail {...noticeData.sender} /> */}
                {noticeData.senders.map((obj) => <AddressRenderer {...obj} />)}
              </div>
              <div className="mt-6">
                <div className={styles.title}>Receipient</div>
                {/* <SenderDetail {...receipientDetail} /> */}
                {noticeData.receivers.map((obj) => <AddressRenderer {...obj} />)}
              </div>

              <div className="grid grid-cols-3 gap-x-4 mt-7">
                <div>
                  <div className={styles.label}>Purpose</div>
                  <div className={styles.text}>{noticeData.noticePurpose}</div>
                </div>

                <div>
                  <div className={styles.label}>Sub Purpose</div>
                  <div className={styles.text}>{noticeData.noticeSubPurpose}</div>
                </div>
                <div>
                  <div className={styles.label}>Notice Period</div>
                  <div className={styles.text}>{`${noticeData.noticePeriodDays} Days`}</div>
                </div>
              </div>
              <div className="flex flex-row justify-end">
                {/* <Button
                  className="ml-4"
                  style={{
                    backgroundColor: '#46BDE1',
                    borderRadius: '0.5rem',
                    color: '#F5F5F5',
                    // width: '180px',
                  }}
                  onClick={() => {
                    navigate('/app/dispute-manager/legal-notices/edit', {
                      state: {
                        // agreementId: agreementData.agreementId,
                        // agreementId,
                        noticeId,
                      },
                    });
                  }}
                >
                  Edit
                </Button> */}
                <Modal
                  overflow="inside"
                  closeOnClickOutside={false}
                  size="calc(30vw)"
                  opened={open}
                  onClose={() => setOpen(!open)}
                  title="Delete Notice"
                >
                  <div className="my-2">
                    <h4>Are you sure you want to delete this notice?</h4>
                    <div className="flex flex-row justify-end">
                      <Button
                        style={{
                          backgroundColor: colors.primary,
                          borderRadius: '0.5rem',
                          color: '#F5F5F5',
                        // width: '180px',
                        }}
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={deleteLoading}
                        className="ml-4"
                        style={{
                          backgroundColor: deleteLoading ? '#ffcccc' : '#ff4d4d',
                          borderRadius: '0.5rem',
                          color: '#F5F5F5',
                        // width: '180px',
                        }}
                        onClick={() => deleteHandler()}
                      >
                        {deleteLoading ? <BeatLoader size={10} color={colors.rejected} /> : 'Delete'}
                      </Button>
                    </div>
                  </div>
                </Modal>
              </div>
              <Tabs
                className="mt-4"
                value={uiConfigs.activeTab}
                onTabChange={(tab) => {
                  setUiConfigs({
                    ...uiConfigs,
                    activeTab: tab,
                  });
                }}
              >
                <Tabs.List>
                  <Tabs.Tab value="notice">Notice</Tabs.Tab>
                  <Tabs.Tab value="documents">Documents</Tabs.Tab>
                  <Tabs.Tab value="emails">Emails</Tabs.Tab>
                  <Tabs.Tab value="tasks">Tasks</Tabs.Tab>
                  <Tabs.Tab value="expenses">Expenses</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel label="Notice" value="notice">
                  <div className="flex flex-col">
                    <div className="flex justify-end m-4">
                      {/* {uiConfigs.loadingContract === loadingStates.LOADING && (
                      <Badge size="xl" color="green">
                        <div className="flex items-center">
                          <CloudUpload size={20} />
                          <Text className="ml-2 lowercase">Saving...</Text>
                        </div>
                      </Badge>
                      )} */}
                      {/* {(uiConfigs.loadingContract === loadingStates.NO_ACTIVE_REQUEST
                              && uiConfigs.lastSaved)
                          && (
                          <Badge size="xl" color="green">
                            <div className="flex items-center">
                              <Check size={20} />
                              <Text className="ml-2 lowercase">
                                Last saved
                                {` ${uiConfigs.lastSavedText}`}
                              </Text>
                            </div>
                          </Badge>
                          )} */}
                      <div className="flex">
                        {uiConfigs.loadingContract !== loadingStates.LOADING && (
                        <>
                          {/* download pdf */}
                          <div>
                            <Modal
                              opened={(configs2.noticeContent === 'file' && !configs2.selectedFile)}
                              size="calc(80vw)"
                              onClose={() => {
                                setConfigs2((prevState) => {
                                  if (!prevState.selectedFile) {
                                    return ({
                                      ...prevState,
                                      noticeContent: null,
                                    });
                                  }
                                  return prevState;
                                });
                              }}
                            >
                              <NewDocumentUploader
                                multiUploadArgs={multiUploadArgs}
                                checkBoxShow
                                hideDeleteButton
                              />
                            </Modal>
                            {/* <div className="flex"> */}
                            <Group
                              value={configs2.noticeContent}
                              onChange={(value) => {
                                setConfigs2((prevState) => ({
                                  ...prevState,
                                  noticeContent: value,
                                }));
                              }}
                            >
                              <div
                                onClick={() => {
                                  setConfigs2((prevState) => ({
                                    ...prevState,
                                    noticeContent: 'file',
                                  }));
                                }}
                                style={{ width: '300px' }}
                              >
                                {/* <div className="flex flex-col justify-end"> */}
                                {!configs2.selectedFile
                                   && (
                                   <Button
                                     value="file"
                                     style={{
                                       backgroundColor: '#46BDE1',
                                     }}
                                   >
                                     Upload Draft
                                   </Button>
                                   )}
                                {configs2.selectedFile
                                    && (
                                    <div className="flex flex-row">
                                      <Text className="font-bold mr-4">{configs2.selectedFile.fileName}</Text>
                                      <Anchor
                                        className="underline"
                                        size="sm"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setConfigs2((prevState) => ({
                                            ...prevState,
                                            selectedFile: null,
                                          }));
                                          multiUploadArgs.unCheckAllFiles();
                                        }}
                                      >
                                        change
                                      </Anchor>
                                    </div>
                                    )}
                              </div>
                              {/* </div> */}
                            </Group>
                            {/* </div> */}
                          </div>
                          {/* use template */}
                          {configs1.templateView && (
                          <Modal
                            overflow="inside"
                            opened
                            onClose={() => {
                              setConfigs1((prevState) => ({
                                ...prevState,
                                templateView: false,
                              }));
                            }}
                            size="calc(80vw)"
                          >
                            <TemplatesList
                              showNewTemplateBtn={false}
                              templateType="Notices"
                              useTemplateFunc={(template) => {
                                console.log({
                                  template,
                                });
                                setConfigs1((prevState) => ({
                                  ...prevState,
                                  templateView: false,
                                  editorContent: template.quillDelta,
                                  editorKey: `key-${Math.random()}`,
                                }));
                              }}
                            />
                          </Modal>
                          )}
                          <div className="w-full flex flex-col">
                            <div className="flex  justify-end">
                              <Button
                                onClick={() => {
                                  setConfigs1((prevState) => ({
                                    ...prevState,
                                    templateView: true,
                                  }));
                                }}
                                style={{
                                  backgroundColor: '#46BDE1',
                                }}
                              >
                                Choose from template
                              </Button>
                            </div>
                            {/* <div className="flex justify-center mt-4">
                              <Editor
                                key={configs.editorKey}
                                content={configs.editorContent}
                                onContentChange={
                                (content) => {
                                  setConfigs((prevState) => ({
                                    ...prevState,
                                    editorContent: content,
                                  }));
                                }
                              }
                              />
                            </div> */}
                          </div>
                          {/*  */}
                          <Button
                            // color="cyan"
                            onClick={
                                    () => {
                                      // saveNoticeContract(contractHtml);
                                      saveNoticeContract(configs1.editorContent);
                                    }
                                  }
                            disabled={uiConfigs.loadingContract === loadingStates.LOADING}
                            // className="w-40 mx-4"
                            className="w-40 ml-4"
                            style={{
                              backgroundColor: '#46BDE1',
                            }}
                          >
                            <Text>Save</Text>
                          </Button>
                          <Button color="gray" onClick={openPreviewUrl} disabled={uiConfigs.previewPdfLoading === loadingStates.LOADING} className="w-60 mx-4">
                            {uiConfigs.previewPdfLoading === loadingStates.LOADING
                              ? <BeatLoader size={10} color={colors.primary} />
                              // : <Text>Open in new tab</Text>}
                              : <Text>Preview</Text>}
                          </Button>
                        </>
                        )}
                      </div>
                    </div>
                    {/* {(configs1.editorContent) ? (
                      <div className="flex flex-col items-center">
                        <Editor
                          key={configs1.editorKey}
                          content={configs1.editorContent}
                          onContentChange={
                                (content) => {
                                  setConfigs1((prevState) => ({
                                    ...prevState,
                                    editorContent: content,
                                  }));
                                }
                              }
                        />
                      </div>
                    ) : null} */}
                    {(debouncedContractHtml)
                      ? (
                        <div className="flex flex-col items-center">
                          <Editor
                            content={debouncedContractHtml}
                            onContentChange={setContractHtml}
                          />
                        </div>
                      ) : null}
                  </div>
                </Tabs.Panel>
                <Tabs.Panel label="Documents" value="documents">
                  <div className="p-6">
                    <NewDocumentUploader multiUploadArgs={multiUploadArgs} />
                  </div>
                </Tabs.Panel>
                <Tabs.Panel label="Emails" value="emails">
                  <EmailTab parent="notice" parentId={noticeId} />
                </Tabs.Panel>
                <Tabs.Panel label="Tasks" value="tasks">
                  <TasksTab parent="notice" parentId={noticeId} title={notice.noticeTitle} />
                </Tabs.Panel>
                <Tabs.Panel label="Expenses" value="expenses">
                  <ExpensesTab parent="notice" parentId={noticeId} />
                </Tabs.Panel>
              </Tabs>
            </div>
            )}
        {/* <div className="mt-4">
          <Button
            style={{ width: '200px' }}
          >
            Send To Email
          </Button>
        </div> */}
      </div>
    </>
  );
};

export default NoticeDetailsPage;
