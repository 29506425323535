/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import { searchApi } from "../utilities/apis/commonApis";
import { apiWrapWithErrorWithData } from "../utilities/apiHelpers";
import { showNotification } from "@mantine/notifications";
import SearchOption from "../components/SearchOption/SearchOption";
import colors from "../utilities/design";

function Search() {
  const { input } = useParams();

  const [debouncedSearchInput] = useDebouncedValue(input, 500);
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    if(debouncedSearchInput.length > 2) {
      fetchSearchInput();
    }
  }, [debouncedSearchInput]);

  const fetchSearchInput = async () => {
    try {
      const response = await apiWrapWithErrorWithData(
        searchApi({ searchInput: debouncedSearchInput })
      );
      //console.log("momo :",response.data.agreements)
      const { agreements, notices, cases } = response.data;
      agreements.forEach((ele) => {
        ele["table"] = "agreements";
        ele["link"] = `/app/agreements/details`;
      });  
      notices.forEach((ele) => {
        ele["table"] = "notices";
        ele["link"] = "/app/dispute-manager/legal-notices/details";
      });
      cases.forEach((ele) => {
        ele["table"] = "cases";
        ele["link"] = "/app/dispute-manager/cases/details";
      });
     
      setSearchData([...agreements, ...notices, ...cases]);
    } catch (err) {
      // showNotification({
      //   color: "red",
      //   title: "dashboard",
      //   message: "Something went wrong.",
      // });
    }
  };
  console.log("searchData", searchData);

  return (
    <div>
      { searchData.length > 0 ? searchData.map((ele) => (
        <SearchOption
          backgroundColor={colors [ele.table.toLowerCase()]}
          module={ele.table.toUpperCase()}
          link={ele.link}
          id={ele.id}
          status={ele.status}
          type={ele.table === 'cases' ? ele.caseData?.json?.caseNumber?.type : ele.table === 'agreements' ? ele.typeOfAgreement : ele.noticeType}
          title={ele.table === 'cases' ? ele.caseTitle : ele.table === 'agreements' ? ele.titleOfAgreement : ele.noticeTitle}
          date={ele.table === 'cases' ? ele.createdAt :ele.table === 'agreements' ? ele.createdAt : ele.createdAt}
        />
      )) : 
        <p style={{ textAlign: 'center', color: '#495057'}}>No results found</p>
      }
    </div>
  );
}

export default Search;
