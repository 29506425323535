import { showNotification } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Skeleton } from '@mantine/core';
import { apiWrapWithErrorWithData } from '../utilities/apiHelpers';
import { expenseMonthlyApi } from '../utilities/apis/budgetManager';
import { loadingStates } from '../utilities/utilities';

function DashboardGraph({ month }) {
  const [configs, setConfigs] = useState({
    listLoading: loadingStates.NO_ACTIVE_REQUEST,
    expenseLoading: loadingStates.NO_ACTIVE_REQUEST,
    list: null,
    expenseList: null,
    // module: 'Case',
  });
  const fetchMonthlyExpense = async () => {
    setConfigs((prevState) => ({
      ...prevState,
      expenseLoading: loadingStates.LOADING,
    }));
    const resp = await apiWrapWithErrorWithData(expenseMonthlyApi({ month }));
    if (resp?.success) {
      setConfigs((prevState) => ({
        ...prevState,
        expenseLoading: loadingStates.NO_ACTIVE_REQUEST,
        expenseList: resp.listExpenseMonth,
      }));
    } else {
      setConfigs((prevState) => ({
        ...prevState,
        expenseLoading: loadingStates.NO_ACTIVE_REQUEST,
      }));
      // showNotification({
      //   title: 'Financial Analytics',
      //   message: "Couldn't load Monthly Expense",
      //   color: 'red',
      // });
    }
  };

  useEffect(() => {
    fetchMonthlyExpense();
  }, [month]);

  return (
    <div className="mb-5">
      <div className="mt-2">
      </div>
      {configs.expenseLoading === loadingStates.LOADING
        && <Skeleton style={{ height: '200px' }} className="mt-4 mx-8" visible />}
      {configs.expenseLoading === loadingStates.NO_ACTIVE_REQUEST && configs.expenseList
        && (
          <div
            className="mt-4 w-full border-solid border-gray-100 border-2 rounded-lg p-4 mt-4"
            style={{ border: 'none', boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1)', marginBottom: '2rem' }}
          >
            <Bar
              data={{
                labels: configs.expenseList.map((el) => `${el.month} ${el.year}`),
                datasets: [
                  {
                    label: 'Expenses',
                    backgroundColor: '#F69E7B',
                    barThickness: 30,
                    barPercentage: 0.5,
                    // data: configs.expenseList.map((el) => (el['type'] === 'Expense' ? el['_sum'].amount : '')),
                    data: configs.expenseList.map(ele => ele.type['Expense']),
                  },

                  {
                    label: 'Recoveries',
                    backgroundColor: '#9EA9F0',
                    barThickness: 30,
                    barPercentage: 0.5,
                    // data: configs.expenseList.map((el) => (el['type'] === 'Recovery' ? el['_sum'].amount : '')),
                    data: configs.expenseList.map(ele => ele.type['Recovery']),
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                },
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: 'Amount (₹)',
                    },
                  },
                  x: {
                    title: {
                      display: true,
                      text: 'Months',
                    },
                  },
                },
              }}
            />
          </div>
        )}
    </div>
  );
}

export default DashboardGraph;
