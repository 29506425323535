import React from 'react';
import useMultiFileUpload from '../../hooks/useMultiFileUpload';
import NewDocumentUploader from '../NewDocumentUploader/NewDocumentUploader';

function CaseRecordFile({ id }) {
  const multiUploadArgs = useMultiFileUpload({ parent: 'caseRecord', parentId: id });
  return (
    <div>
      <NewDocumentUploader
        hideDeleteButton
        hideDropZone
        multiUploadArgs={multiUploadArgs}
        fileNameHidden
      />
    </div>
  );
}

export default CaseRecordFile;
