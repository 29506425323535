/* eslint-disable */
import React, { useState } from 'react';
import { Avatar, Menu, Text, Input, CloseButton } from '@mantine/core';
import { useSelector } from 'react-redux';
import { AiOutlineSearch } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import styles from './AppHeader.module.css';
import { assetsPrefix } from '../../utilities/utilities';
import { selectUserData } from '../../redux/selectors';
import { DoorExit } from 'tabler-icons-react';
import { deleteCookie, getCookiesSession } from '../../cookiesSession';

const AppHeader = () => {
  const userData = useSelector(selectUserData);
  const [opened, setOpened] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();

  const logoutHandler = () => {
      if (getCookiesSession('trueCounselUserData')) {
          deleteCookie('trueCounselUserData');
      }
      window.location = process.env.REACT_APP_LANDING_PAGE_URL;
  };
  return (
    <div className="flex flex-row justify-between mx-8 my-2">
      <img className={styles.logo} src={`${assetsPrefix}/images/trueCounselLogo.svg`} alt="TrueCounsel" />
      <div className='flex'>
        <Input 
            icon={<AiOutlineSearch />}
            value={searchInput}
            rightSection={
                <CloseButton onClick={() => { setSearchInput(''); navigate('/app') }} />
            } 
            placeholder='Search' 
            className='mx-3'
            onChange={(e) => { 
                const input = e.target.value;
                input.length > 2 ? navigate(`/app/search/${input}`) : navigate('/app'); 
                setSearchInput(input); 
            }}
        />
        <Menu>
            <Menu.Target>
                <div onClick={() => { setOpened(!opened) }} className="flex items-center cursor-pointer">
                    {userData.picture?.length ? <Avatar size="md" src={userData.picture} radius="xl" />
                        : <Avatar size="md" radius="xl">{userData.name[0].toUpperCase()}</Avatar>}
                    <div className="ml-2 flex flex-col">
                        <Text size="md">{userData.name}</Text>
                        <Text size="xs">{userData.email}</Text>
                    </div>
                </div>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item onClick={logoutHandler} icon={<DoorExit size={14} />}>Logout</Menu.Item>
            </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  );
};

export default AppHeader;
